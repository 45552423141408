import './TableLider.css'
import GetScore from './GetScore'

import medal from './image/medal.png'
import close from './image/goldClose.png'
import logo from "./image/logo.png"
import { useEffect, useState } from 'react'


const TableLiders = props => {
    //Все работает, сейчас обновляю, жду когда появится 10 записей и будут делать таблицу лидеров

    const [tableL, setTableL] = useState(null)
    useEffect(()=>{
        const fetchScores = async () => {
            const topScores = await GetScore();
            if (topScores) {
                setTableL(topScores);
            }
          };
      
          fetchScores();
    },[])

    const test = () => {
        console.log(tableL)
        // tableL.map((item)=>{
        //     console.log(item)
        // })

    }

    return (
        <div className="contTable" >
            <div className="windowTable" >
             <img src={close} alt="X" className="closeTable" onClick={()=>props.getVisTable()} />
            <div className="contHTable">
                <h1 className="hTable">Десять богатейших людей игры</h1>
            </div>
            <div className="blockLiders">
            {tableL &&  tableL.map((item, id)=> 
            <div className="contBlockTable" onClick={test} key={id}>
            <div className="contSoundTable" >
                 {item.photo ? <img src={item.photo} className="tableImg" alt="?" />: <img src={logo} className="tableImg" alt="?" />}
            </div>
                <div className="descTable">
                {item.userName ? <p className="pTable">{id+1}:{item.userName}</p>:<p className="pTable">{id+1}:Миллионер</p>}
                <p className="pTableDown"><img src={medal} alt="|" className="medalTable"/> {item.score}</p>
                </div>
            </div>
            )}
            </div>
            </div>
        </div>
    )
}

export default TableLiders