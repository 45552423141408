export default function Match(gorizNum, chooseIndA, chooseIndB, func, func2, getScore) {
    let newGorizNum = [...gorizNum]; // Глубокое копирование gorizNum
    let gold = 0
    let gold2
    let score = 0

    function sumEl(arr) {
        let sum = 0;
        for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < arr[i].length; j++) {
                sum += arr[i][j];
            }
        }

        return sum;
    }
    
    const allNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,1001,1002,1003];
    const allNumbers2 = [1001,1002,1003, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1];
    const ob = [
        [0,0,0,0,0,0],
        [0,0,0,0,0,0],
        [0,0,0,0,0,0],
        [0,0,0,0,0,0],
        [0,0,0,0,0,0],
        [0,0,0,0,0,0]
    ]
    let obArray = ob.map(innerArray => [...innerArray]);

    //Переменная чтобы не было дубля в золоте
    let duble=-5
    //Считаем золото
    const countGold = (number) => {
        // console.log("Выховголд")
        if (number === 1) {
            gold = 5
            score = 30
          } else if (number === 2) {
            gold = 10;
            score = 100
          } else if (number === 3) {
            gold = 15;
            score = 500
          } else if (number === 4) {
            gold = 30;
            score = 1500
          } else if (number === 5) {
            gold = 50;
            score = 4000
          } else if (number === 6) {
            gold = 100;
            score = 8000
          } else if (number === 7) {
            gold = 1500;
            score = 16000
          } else if (number === 8) {
            gold = 5000;
            score = 30000
          } else if (number === 9) {
            gold = 12000;
            score = 100000
          } else if (number === 10) {
            gold = 25000;
            score = 300000
          } else if (number === 11) {
            gold = 50000;
            score = 500000
          } else if (number === 12) {
            gold = 100000;
            score = 10000000
          } else if (number === "0") {
            gold = 4;
            score = 30
          } else if (number === 1001) {
            gold = 14;
            score = 4000
          } else if (number === 1002) {
            gold = 21;
            score = 26000
          } else if (number === 1003) {
            gold = 28;
            score = 50000
          }
          if (duble!==gold) {
          duble=gold
          func2(gold, chooseIndB, chooseIndA)
          }
          getScore(score)
    }
    const getGold = (num, indA, indB)=> {

            if (num === 1001) {
                gold2 = 7} 
            else if (num === 1002) {
                gold2 = 14;} 
            else if (num === 1003) {
                gold2 = 21;} 
            else if (num === 1004) {
                gold2 = 28;
    }
    func(gold2, indA, indB)
}

    //Меняем 1000 на 1001
    const replace = () => {
        const newMatrix = newGorizNum.map(row => {
            return row.map(element => {
              return element === 1000 ? 1001 : element;
            });
          });
          newGorizNum=newMatrix;
        //   checkValue()
    }
    

    

    //Меняем индексы
    const replaceNum = (find, number) => {

        //Флаг от дублей
        let alredyReplace = true
        find.forEach(index => {
            const [i, j] = index;
            if (chooseIndA===i && chooseIndB===j) {
                if (alredyReplace) {
                    // console.log("Меняем")
                    newGorizNum[i][j]=number+1
                    alredyReplace=false
                }
            } else {
            newGorizNum[i][j] = 0;
            countGold(number)
            replace()
            }
        });
    }

    //Убираем одинаковые элементы с массива
    const removeDuplicateArrays = (array, number) => {
        const uniqueArrays = [];
        const stringifiedArrays = [];
    
        array.forEach(subArray => {
            const stringifiedSubArray = JSON.stringify(subArray);
            if (!stringifiedArrays.includes(stringifiedSubArray)) {
                stringifiedArrays.push(stringifiedSubArray);
                uniqueArrays.push(subArray);
            }
        }); 
        if (uniqueArrays.length >2) {
            replaceNum(uniqueArrays, number)
        }

        return uniqueArrays;
    }

    //Фунfindкция поиска соседних по индексам
    const findNumbers = (indices, number) => {
        let find1 = []
        for (let i = 0; i < indices.length; i++) {
            //Если первое число равно последующим в списке справа
            for (let j = 0; j<indices.length; j++) {
                for (let k = 0; k<indices.length; k++) {
                if(i!==j && 
                    indices[i][0] === indices[j][0] && 
                    indices[i][1] === (indices[j][1]-1) && 
                    i!==k && 
                    ((indices[j][0] === indices[k][0] && indices[j][1] === (indices[k][1]-1)) || (indices[j][0] === (indices[k][0]-1) && indices[j][1] === (indices[k][1]))|| (indices[j][0] === (indices[k][0]+1) && indices[j][1] === (indices[k][1])))) {
                    find1.push(indices[i])
                    find1.push(indices[j])
                    find1.push(indices[k])
                }
                else if (i!==j &&
                    indices[i][0] === (indices[j][0]-1) &&
                    indices[i][1] === (indices[j][1])  &&
                    i!==k && 
                    ((indices[j][0] === (indices[k][0]-1) && indices[j][1] === (indices[k][1])) || (indices[j][0] === indices[k][0] && indices[j][1] === (indices[k][1]-1)) || (indices[j][0] === (indices[k][0]+1) && indices[j][1] === (indices[k][1]-1)))) {
                    find1.push(indices[i])
                    find1.push(indices[j])
                    find1.push(indices[k])
                }
            }
    }
}
    removeDuplicateArrays(find1, number)
    return find1;
}

    //Функция вычисления
    const count = (obArray, number) => {
        const indices = [];
    
        for (let i = 0; i < obArray.length; i++) {
            for (let j = 0; j < obArray[i].length; j++) {
                if (obArray[i][j] === number) {
                    indices.push([i, j]);
                }
            }
        }
        findNumbers(indices, number)

        return indices;
    }


    function iterateNumbers(ind) {
        obArray = ob.map(innerArray => [...innerArray]);

        if (ind >= allNumbers.length) {
            func(0,111)
            return ; // если достигнут конец списка чисел, прекратить выполнение
        } else {
        const number = allNumbers[ind];

        for (let i = 0; i < newGorizNum.length; i++) {
            for (let j = 0; j < newGorizNum[i].length; j++) {
                if(newGorizNum[i][j]===1000) {
                    obArray[i][j]=number
                }

                else if (newGorizNum[i][j]===number) {
                    obArray[i][j]=number
                }
                }
            }
            if ((sumEl(obArray)/number)>2) {
                count(obArray, number)
            }

        

        iterateNumbers(ind + 1); // переход к следующему числу
    }
}

function iterateNumbers2(ind) {
    obArray = ob.map(innerArray => [...innerArray]);

    if (ind >= allNumbers2.length) {
        func(0,111)
        return ; // если достигнут конец списка чисел, прекратить выполнение
    } else {
    const number = allNumbers2[ind];

    for (let i = 0; i < newGorizNum.length; i++) {
        for (let j = 0; j < newGorizNum[i].length; j++) {

            if(newGorizNum[i][j]===1000) {
                obArray[i][j]=number
            }

            else if (newGorizNum[i][j]===number) {
                obArray[i][j]=number
            }
            }
        }
        if ((sumEl(obArray)/number)>2) {
            count(obArray, number)
        }

    

    iterateNumbers2(ind + 1); // переход к следующему числу
}
}

//Если есть 100 запускаем другой массив
const checkValue = () => {
    let found = false;
  
    for (let row of gorizNum) {
      for (let element of row) {
        if (element === 1000) {
          found = true;
          break;
        }
      }
      if (found) break;
    }
  
    if (found) {
        iterateNumbers2(0)
    } else {
        iterateNumbers(0); // начать итерацию с 0-го индекса
    }
  }

  const checkGold = ()=>{
    for (let i = 0; i < gorizNum.length; i++) {
        for (let j = 0; j < newGorizNum[i].length; j++) {
            //Золото со стоящих мешочков
            if (newGorizNum[i][j]===1001 || newGorizNum[i][j]===1002||newGorizNum[i][j]===1003||newGorizNum[i][j]===1004) {
                getGold(newGorizNum[i][j], j, i)
            }
  }
}}

    checkGold()
    checkValue()
    replace()


    return [newGorizNum, gold];
}
