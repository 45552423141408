import './ModalHint.css'
import p1 from'./pogony/p1.png'
import p2 from'./pogony/p2.png'
import p3 from'./pogony/p3.png'
import p4 from'./pogony/p4.png'
import p5 from'./pogony/p5.png'
import p6 from'./pogony/p6.png'
import p7 from'./pogony/p7.png'
import p8 from'./pogony/p8.png'
import p9 from'./pogony/p9.png'
import p10 from'./pogony/p10.png'
import p11 from'./pogony/p11.png'
import p12 from'./pogony/p12.png'
import p13 from'./pogony/p13.png'
import p100 from'./pogony/p100.png'
// import p1000 from'./pogony/p1000.png'
import p1001 from'./pogony/p1001.png'
import p1002 from'./pogony/p1002.png'
import p1003 from'./pogony/p1003.png'
import p1004 from'./pogony/p1004.png'
import right from'./image/right.png'
import lock from './image/lock.png'
import plus from './image/plus.png'
import grass from './image/grass2.JPG'
import quiz from './image/quizWhite.png'

const ModalHint = props => {

    
    return (
        <div className="contHint"
        style={{
            left: props.index===0 ? "0px": props.index===1 ? "-62px" : "none",
            right:props.index ===4 ? "-64px": props.index===5 ? "0px": "none"
        }}
        >
            {props.number===1 || props.number===2 || props.number===3 || props.number===4 || props.number===5 || props.number===6 || props.number===7 || props.number===8 || props.number===9 || props.number===10 || props.number===11 || props.number===12 || props.number===1001 || props.number===1002 || props.number===1003 || props.number===1004 ?
            <div>
            <img className="imgHint1" alt="1" src={props.number===1 ? p1 : props.number===2 ? p2 : props.number===3 ? p3 : props.number===4 ? p4 : props.number===5 ? p5 : props.number===6 ? p6 : props.number===7 ? p7 : props.number===8 ? p8 : props.number===9 ? p9 : props.number===10 ? p10 : props.number===11 ? p11 : props.number===12 ? p12 : props.number===13 ? p13 : props.number===1001 ? p1001: props.number===1002 ? p1002: props.number===1003 ? p1003: props.number===1004 ? p1004: quiz  }/>
            <img className="imgHint2" alt="1" src={props.number===1 ? p1 : props.number===2 ? p2 : props.number===3 ? p3 : props.number===4 ? p4 : props.number===5 ? p5 : props.number===6 ? p6 : props.number===7 ? p7 : props.number===8 ? p8 : props.number===9 ? p9 : props.number===10 ? p10 : props.number===11 ? p11 : props.number===12 ? p12 : props.number===13 ? p13 : props.number===1001 ? p1001: props.number===1002 ? p1002: props.number===1003 ? p1003: props.number===1004 ? p1004: quiz  }/>
            <img className="imgHint3" alt="1" src={props.number===1 ? p1 : props.number===2 ? p2 : props.number===3 ? p3 : props.number===4 ? p4 : props.number===5 ? p5 : props.number===6 ? p6 : props.number===7 ? p7 : props.number===8 ? p8 : props.number===9 ? p9 : props.number===10 ? p10 : props.number===11 ? p11 : props.number===12 ? p12 : props.number===13 ? p13 : props.number===1001 ? p1001: props.number===1002 ? p1002: props.number===1003 ? p1003: props.number===1004 ? p1004: quiz  }/>
            <img className="imgHint4" alt="1" src={props.number===1 ? p2 : props.number===2 ? p3 : props.number===3 ? p4 : props.number===4 ? p5 : props.number===5 ? p6 : props.number===6 ? p7 : props.number===7 ? p8 : props.number===8 ? p9 : props.number===9 ? p10 : props.number===10 ? p11 : props.number===11 ? p12 : props.number===12 ? p13: props.number===1001 ? p1002: props.number===1002 ? p1003: props.number===1003 ? p1004: quiz  }/>
            <img src={right} alt=">" className="imgHint5"/>
            </div>:
            <div>
                <img src={lock} alt="?" className="hintLock1"/>
                <img src={plus} alt="?" className="hintPlus"/>
                <img src={p100} alt="?" className="hintLock2"/>
                <img src={right} alt="?" className="hintLockRight"/>
                <div className="hintLock4"/>
                <img src={grass} alt="?" className="hintLock3"/>
            </div>
}
        </div>
    )
}
 export default ModalHint