import './FinishScreen.css'
import close from './image/goldClose.png'
import VkAds from './vkAds.js'

const StepsOut = props => {
    
    const getStepAdd = () => {
        VkAds(props.stepPlus)
    }
    const getInfinity = () => {
        props.getNumberBuy(2)
        // props.stepInfinity()
    }

    return (
        <div className="contFinish">
            <div className="windowFinish">
                <img src={close} alt="X" onClick={()=>props.getStepVis()} className="closeSteps"/>
                <p className="textFinish" style={{fontSize:"30px"}}>Упс...</p>
                <p className="textFinish">У тебя больше не осталось ходов.</p>
                <p className="textFinish">Посмотри рекламу чтобы добавить ходов, или купи бесконечное количество ходов.</p>
                <div className="btnFinish" style={{marginBottom:"20px"}} onClick={getStepAdd}>
                  <p>Посмотреть</p>
                </div>
                <div className="btnFinish" style={{backgroundColor:"#31791b"}} onClick={getInfinity}>
                    <p style={{color:"#FFD700"}}>Купить</p>
                </div>
            </div>
        </div>
    )
}

export default StepsOut