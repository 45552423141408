import p0 from './pogony/p0.png'
import './NumberAnime.css'

const NumberAnime = props => {


    return (
        <div className="contAnime">
            <img src={p0} alt="G" className="imgAnime"/>
            <p className="pAnime">{props.countAnime}</p>
        </div>
    )
}

export default NumberAnime