import './MenuDem.css'
import React, { useEffect, useState } from 'react';
import NewGame from './NewGame.js'
import soundOn from './image/soundOn.png'
import redLine from './image/redLine.png'
import play from './image/playGold.png'
import reload from './image/reloadGold.png'
import share from './image/shareGold.png'
import close from './image/goldClose.png'
import invite from './image/inviteGold.png'
import p0 from './pogony/p0.png'
// import add from './image/add.png'
import table from './image/table.png'
// import get from './sounds/backSound.mp3'
// import get from "./sounds/get.mp3"
// import collect from './sounds/collect.mp3'
// import agentSell from './sounds/agentSell.mp3'
import VkInvite from './VkInvite.js';
import VkShare from './VkShare.js'
import BigNumber from './BigNumber.js';
// import VkHomeScreen from './VkHomeScreen.js'
import maxValue from './MaxValue.js'
import TableLiders from './TableLiders';

// import TableUsers from './TableUsers.js';


const MenuDem = props => {
    // const audioCollectRef = useRef(null)
    // const audioAgentSellRef = useRef(null)

    const [getMaxValue, setGetMaxValue] = useState(0)
    const [visNewGame, setVisNewGame] = useState(false)
    const [maxItem, setMaxItem] = useState('')
    const [visTable, setVisTable] = useState(false)

    const maxShare = props.maxShare

    useEffect(()=>{
        if (props.gorizNum) {
        setGetMaxValue(maxValue(props.gorizNum))
        }
        // eslint-disable-next-line
    },[])


    useEffect(()=>{
        setMaxItem(BigNumber(props.gorizNum))
    },[props])

    const closeNewGame = () => {
        setVisNewGame(false)
    }

    const openNewGame = () => {
        setVisNewGame(true)
    }

    const mute = () => {
        props.getMute()
        props.stopSoundBack()
    }

    const playSound = () => {
        props.playSoundBack()
        //   const audio = document.getElementById('get');
        //   audio.play(); 
        } 
    const getVisTable = () => {
        setVisTable(!visTable)
    }


    return (
        <div className="contMenuDem" >
            {visNewGame && <NewGame closeNewGame={closeNewGame}/>}
            {/* <img src={closeMenu} alt="X" style={{width:"45px", height:"45px", position:"absolute", top:"10px", right:"0px", backgroundColor:"white", borderRadius:"50%", zIndex:"10"}} onClick={()=>props.closeModel()}/> */}
            <div className="windowMenuDem" >
             <img src={close} alt="X" className="closeMenu" onClick={()=>props.closeModalDem()} />
            <div className="contHMenu">
                <h1 className="hMenu">М Е Н Ю</h1>
            </div>
            {!props.mute ? 
            <div className="contBlock" onClick={mute}>
            <div className="contSound">
                <img src={soundOn} className="soundImg" alt="mute" />
            </div>
            <p className="pSound">Звуки включены</p>
            </div> : 
            <div className="contBlock" onClick={()=> {mute(); playSound()}}>
            <div className="contSound" style={{position:"relative"}} >
                <img src={soundOn} className="soundImg" alt="sound" />
                <img src={redLine} className="soundImgClose" alt="sound" />
            </div>
            <p className="pSound">Звуки выключены</p>
            </div>

    }
            <div className="contBlock" onClick={()=>props.closeModalDem()}>
            <div className="contSound">
                 <img src={play} className="soundImg" alt="mute" />
            </div>
            <p className="pSound">Продолжить игру</p>
            </div>
            <div className="contBlock" onClick={openNewGame}>
            <div className="contSound">
                 <img src={reload} className="soundImg"  alt="mute" />
            </div>
            <p className="pSound">Начать с начала</p>
            </div>
            {/* <div className="contBlock" onClick={TableUsers(props.score)}>
            <div className="contSound">
                 <img src={table} className="soundImg"  alt="mute" style={{width:"45px", height:"45px", marginLeft:"5px"}}/>
            </div>
            <p className="pSound">Турнирная таблица</p>
            </div> */}
            {getMaxValue>maxShare && 
            <div className="contBlock" style={{position:"relative"}} onClick={()=>{VkShare(maxItem, 1, props.inviteGold, getMaxValue)}}>
            
                <div className="bonusCont">
                    <p className="bonusText">+3000</p>
                    <img className="bonusImg" src={p0} alt="o" />
                </div>

            <div className="contSound">
                 <img src={share} className="soundImg" style={{width:"40px", height:"40px", marginTop:"5px", marginBottom:"5px"}} alt="mute" />
            </div>
            <p className="pSound" style={{marginLeft:"22px"}}>Поделиться результатом</p>
            </div>
            }
            <div className="contBlock" onClick={()=>{VkInvite()}}>
            <div className="contSound">
                 <img src={invite} className="soundImg"  alt="mute" />
            </div>
            <p className="pSound">Пригласить друзей</p>
            </div>
            <div className="contBlock" onClick={getVisTable}>
            <div className="contSound">
                 <img src={table} className="soundImg" style={{width:"45px", height:"45px", marginBottom:"2.5px", marginTop:"2.5px"}} alt="mute" />
            </div>
            <p className="pSound">Таблица лидеров</p>
            </div>
            
            {/* {get && <audio id="get"  src={get}></audio>} */}
            {visTable && <TableLiders getVisTable={getVisTable}/>}
        </div>
        </div>
    )
}

export default MenuDem