import medal from './image/medal.png'
import './NumberAnime.css'

const ScoreAnime = props => {


    return (
        <div className="contAnimeScore">
            <img src={medal} alt="G" className="imgAnimeScore"/>
            <p className="pAnimeScore">{props.localScore}</p>
        </div>
    )
}

export default ScoreAnime