import p0 from "./pogony/p0.png"
import p1 from'./pogony/p1.png'
import p2 from'./pogony/p2.png'
import p3 from'./pogony/p3.png'
import p4 from'./pogony/p4.png'
import p5 from'./pogony/p5.png'
import p6 from'./pogony/p6.png'
import p7 from'./pogony/p7.png'
import p8 from'./pogony/p8.png'
import p9 from'./pogony/p9.png'
import p10 from'./pogony/p10.png'
import p11 from'./pogony/p11.png'
import p12 from'./pogony/p12.png'
import p13 from'./pogony/p13.png'
import p100 from'./pogony/p100.png'
import p300 from './pogony/p300.png'
import p1000 from'./pogony/p1000.png'
import p1001 from'./pogony/p1001.png'
import p1002 from'./pogony/p1002.png'
import p1003 from'./pogony/p1003.png'
import p1004 from'./pogony/p1004.png'
import close from './image/goldClose.png'
import topWall from '../democracy/image/topWall.JPG'
import medal from './image/medal.png'
import lowyer from './image/lowyer.png'
import quiz from './image/quizWhite.png'

import "./information.css"
import { useEffect, useState } from "react"

const Information = props => {

    const [maxNumber, setMaxNumber] = useState(1)

    useEffect(()=>{
        const flattenedArray = props.gorizNum.flat();
    
        // Отфильтруем значения меньше 300
        const filteredArray = flattenedArray.filter(num => num < 300);
      
        // Найдем максимальное значение среди отфильтрованных
        const maxNumberGet = Math.max(...filteredArray);
        setMaxNumber(maxNumberGet)
    },[props])

    const closeWindow = () => {
        props.closeInfo()
    }
    return (
        <div className="contInfo"  >
            <div style={{backgroundImage: `url(${topWall})`, backgroundSize: 'cover', position:"fixed", top:"0", left:"0", width:"100%", maxWidth:"450px" ,height:"30%"}} ></div>
            <div className="under"/>
           <div style={{position:"absolute", width:"100%", height:"100%" }}>
            <div className="contHeadInfo">
                <h3 className="contH">Че почем?</h3>
            </div>
            <img src={close} alt="X" className="closeInfo" onClick={closeWindow} />
            <div style={{width:"100%", height:"85vh", overflow:"auto"}}>
            <div className="contHeadInfo" style={{width:"70%", margin:"auto", marginBottom:"10px", marginTop:"10px"}}>
                <h4 className="contH" style={{fontSize:"24px", color:"white"}}>Основной бизнес</h4>
            </div>
            <div className="eachInfo">
                <img src={p1} alt="1" className="infoImg" />
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">Копейка рубль бережет! Монетка к моенетке, а там и на серебро хватит!</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">0</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">0</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">0</p>
                </div>
                </div>
            </div>
            <div className="eachInfo">
                <img src={p2} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">Серебряная монета - большое начало! Три в копилку, и до золота недалеко!</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">30</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">5</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">2</p>
                </div>
                </div>
            </div>
            <div className="eachInfo">
                <img src={p3} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">А вот и золотишко! Три монетки и на стартовый капитал хватит!</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">100</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">10</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">5</p>
                </div>
                </div>
            </div>
            <div className="eachInfo">
                <img src={p4} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">Стартовый капитал готов! Осталось собрать побольше и можно начинать бизнес!</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">500</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">15</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">7</p>
                </div>
                </div>
            </div>
            <div className="eachInfo">
                <img src={p5} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">С малого бизнеса начинаются большие дела! Кафе открыто, ждем первых клиентов!</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">1500</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">30</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">15</p>
                </div>
                </div>
            </div>
            <div className="eachInfo">
                <img src={p6} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">Нанимай продавцов, товар закуплен! Магазинчик к магазинчику а там и на недвижимость хватит!</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">4000</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">50</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">25</p>
                </div>
                </div>
            </div>
            <div className="eachInfo">
                <img src={p7} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">Неджвижимость - основа бизнеса! Еще пару отелей и можно пробовать свои силы в промышленности!</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">8000</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">100</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">50</p>
                </div>
                </div>
            </div>
            <div className="eachInfo">
                <img src={p8} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">Завод дело хорошее! Но для полноценной работы не помещает электростанция!</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">16000</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">1500</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">750</p>
                </div>
                </div>
            </div>
            {maxNumber >7 &&<div className="eachInfo">
                <img src={p9} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">Все готово для большого бизнеса! Еще пара электростанций и пора подумать о больших деньгах!</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">30000</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">5000</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">2500</p>
                </div>
                </div>
            </div>
            }
            {maxNumber >8 &&<div className="eachInfo">
                <img src={p10} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">С собственным банком дела пойдут быстрее! Деньги от населения можно пустить на финансирование более масштабных проектов!</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">100000</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">12000</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">6000</p>
                </div>
                </div>
            </div>
            }
            {maxNumber >9 &&<div className="eachInfo">
                <img src={p11} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">Собственная железная дорога! Грузовые и пассажирские перевозки по земле, а что если попробовать свои силы в небе!</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">300000</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">25000</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">15000</p>
                </div>
                </div>
            </div>
            }
            {maxNumber>10 && <div className="eachInfo" >
                <img src={p12} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">Авиакомпания позволяет осуществлять транспортировки по всему миру! Заработки выше, амбиций больше!</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">500000</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">50000</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">30000</p>
                </div>
                </div>
            </div>
            }
            {maxNumber>11 && <div className="eachInfo" >
                <img src={p13} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">Нефтедобывающая компания - крупнейший бизнес, мировые продажи - миллиардные доходы. Больше вышек - больше возможностей!</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">1000000</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">100000</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">50000</p>
                </div>
                </div>
            </div>
            }
            <div className="eachInfo" >
                <img src={quiz} alt="1" className="infoImg" style={{width:"60px", height:"60px"}}/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo" style={{marginBottom:"0px"}}>Продолжай строить бизнес империю, зарабатывай и открывай новые активы!</p>
                </div>
            </div>
            <div className="contHeadInfo" style={{width:"70%", margin:"auto", marginBottom:"10px", marginTop:"10px"}}>
                <h4 className="contH" style={{fontSize:"24px", color:"white"}}>Дополнения</h4>
            </div>
            <div className="eachInfo" style={{border:"2px solid #FFD700"}}>
                <img src={p1000} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">За деньги можно купить всё, магазин отель или даже банк! Если рядом ничего нет, начнется строительстов игрового бизнеса.</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">-</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">-</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">-</p>
                </div>
                </div>
            </div>
            <div className="eachInfo" style={{border:"2px solid #FFD700"}}>
                <img src={p100} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">Налоговый агент накладывает арест и продает имущество! Выручка с продажи уходит на оплату налогов, лишь часть достается владельцу.</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">-</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">-</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">-</p>
                </div>
                </div>
            </div>
            <div className="eachInfo" style={{border:"2px solid #FFD700"}}>
                <img src={lowyer} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">Юрист - самый ценный сотрудник! Передвинет любое имущество на поле, но он часто бывает занят.</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">-</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">-</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">-</p>
                </div>
                </div>
            </div>
            <div className="eachInfo" style={{border:"2px solid #FFD700"}}>
                <img src={p300} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">Рейдер - коварный субъект, захватывает и блокирует любой актив. Единственный способ избавиться от захваченного актива - сдать его налоговому агенту.</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">-</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">-</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">-</p>
                </div>
                </div>
            </div>
            <div className="contHeadInfo" style={{width:"70%", margin:"auto", marginBottom:"10px", marginTop:"10px"}}>
                <h4 className="contH" style={{fontSize:"24px", color:"white"}}>Игровой бизнес</h4>
            </div>
            <div className="eachInfo" style={{border:"2px solid #31791b"}}>
                <img src={p1001} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">Когда деньги девать некуда, можно начать строить игровой бизнес, который хоть и по копеечке, но стабильно приносит доход!</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">0</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">7</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">100</p>
                </div>
                </div>
            </div>
            <div className="eachInfo" style={{border:"2px solid #31791b"}}>
                <img src={p1002} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">Какой игровой бизнес обойдется без однорукого бандита? Доход выше, перспектив больше!</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">4000</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">500</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">250</p>
                </div>
                </div>
            </div>
            <div className="eachInfo" style={{border:"2px solid #31791b"}}>
                <img src={p1003} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">Что наша жизнь? Игра! Покер - серьезные участники, большие деньги, и лишь один шаг до мечты!</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">26000</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">1000</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">10000</p>
                </div>
                </div>
            </div>
            <div className="eachInfo" style={{marginBottom:"30px", border:"2px solid #31791b"}} >
                <img src={p1004} alt="1" className="infoImg"/>
                <div style={{marginLeft:"20px"}}>
                <p className="textInfo">Казино - предел мечтаний настоящего бизнесмена! Стремиться больше некуда, осталось только получать доход!</p>
                <div className="blockDown">
                    <img src={medal} className="imgDown" alt="m"/>
                    <p className="pDown">50000</p>
                    <img src={p0} className="imgDown" alt="g"/>
                    <p className="pDown">1000</p>
                    <img src={p100} className="imgDown" alt="a"/>
                    <p className="pDown">15000</p>
                </div>
                </div>
            </div>
            </div>
            </div>
        </div>
    )
}

export default Information