
const maxValue = (data) => {

    const flattenedArray = data.flat();

    // Отфильтруем значения меньше 300
    const filteredArray = flattenedArray.filter(num => num < 300);
  
    // Найдем максимальное значение среди отфильтрованных
    const maxNumber = Math.max(...filteredArray);

    return maxNumber
}

export default maxValue