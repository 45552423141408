import './FinishScreen.css'
import WindowVkBuy from './WindowVkBuy'
import { useState } from 'react'

const VkBuy = props => {

    const [visAllarm, setVisAlarm] = useState(false)
    const [getNumb, setGetNumb] = useState(null)

    const close = () => {
        props.getNumberBuy(0)
        setVisAlarm(false)
        
    }
    const openAlarm = (numb) => {
        setVisAlarm(true)
        setGetNumb(numb)
    }

    const buy = (item, func) => {
        WindowVkBuy(item, func, openAlarm)
    }


    return (
        <>
        {visAllarm ? 
            <div className="contFinish" style={{zIndex:"10010"}}>
                <div className="windowFinish">
                    <p className="textFinish" style={{fontSize:"30px"}}>{getNumb===1 ? "Поздравляем!!!" : "Ошибка"}</p>
                    <p className="textFinish">{getNumb===1 ? "Ваша покупка прошла успешно!!!": "Ваша покупка не состоялась, попробуйте позднее"}</p>
                    <div className="btnFinish" style={{backgroundColor:"#31791b"}} onClick={close}>
                <p style={{color:"#FFD700"}}>Закрыть</p>
            </div>
                </div>
            </div> :
        <>
        {props.numberBuy === 1 &&
        <div className="contFinish" style={{zIndex:"10010"}}>
        <div className="windowFinish">
            <p className="textFinish" style={{fontSize:"30px"}}>Покупка:</p>
            <p className="textFinish">Заплатить юристу, и получить его внеочередную помощь (однократно). <br/><br/>Цена - 7 голосов.</p>
            <div className="btnFinish" style={{marginBottom:"20px", height:"20px"}} onClick={()=> buy("lowyer", props.buyLowyer)}>
              <p>Купить</p>
            </div>
            <div className="btnFinish" style={{backgroundColor:"#31791b"}} onClick={close}>
                <p style={{color:"#FFD700"}}>Отменить</p>
            </div>
        </div>
    </div>
    }
    {props.numberBuy === 2 &&
        <div className="contFinish" style={{zIndex:"10010"}}>
        <div className="windowFinish">
            <p className="textFinish" style={{fontSize:"30px"}}>Покупка:</p>
            <p className="textFinish">Купить бесконечное количество ходов (покупка действует для всех последующих игр). <br/><br/>Цена - 16 голосов.</p>
            <div className="btnFinish" style={{marginBottom:"20px"}} onClick={()=> buy("steps", props.stepInfinity)}>
              <p>Купить</p>
            </div>
            <div className="btnFinish" style={{backgroundColor:"#31791b"}} onClick={close}>
                <p style={{color:"#FFD700"}}>Отменить</p>
            </div>
        </div>
    </div>
}   
    {props.numberBuy === 3 &&
        <div className="contFinish" style={{zIndex:"10010"}}>
        <div className="windowFinish">
            <p className="textFinish" style={{fontSize:"30px"}}>Покупка:</p>
            <p className="textFinish">Навсегда избавиться от рейдера. При покупке в текущей и последующих играх, рейдер не будет появляться в игре.<br/><br/>Цена - 16 голосов.</p>
            <div className="btnFinish" style={{marginBottom:"20px"}} onClick={()=> buy("raider", props.buyRaider)}>
              <p>Купить</p>
            </div>
            <div className="btnFinish" style={{backgroundColor:"#31791b"}} onClick={close}>
                <p style={{color:"#FFD700"}}>Отменить</p>
            </div>
        </div>
    </div>
} 
{props.numberBuy === 4 &&
        <div className="contFinish" style={{zIndex:"10010"}}>
        <div className="windowFinish">
            <p className="textFinish" style={{fontSize:"30px"}}>Покупка:</p>
            <p className="textFinish">Купить 60 000 монет. <br/><br/>Цена - 29 голосов.</p>
            <div className="btnFinish" style={{marginBottom:"20px"}} onClick={()=> buy("gold", props.buyGold)}>
              <p>Купить</p>
            </div>
            <div className="btnFinish" style={{backgroundColor:"#31791b"}} onClick={close}>
                <p style={{color:"#FFD700"}}>Отменить</p>
            </div>
        </div>
    </div>
} 
</>
}

    </>
    )
}

export default VkBuy