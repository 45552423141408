import { useEffect, useState } from 'react'
import BigNumber from './BigNumber'
import CleanStorage from './CleanStorage'
import './FinishScreen.css'
import VkShare from './VkShare.js'



const FinishScreen = props => {

    const [maxItem, setMaxItem] = useState('')

    useEffect(()=>{
        setMaxItem(BigNumber(props.gorizNum))
    },[props])

    const newGame = () => {
        CleanStorage()
    }

    return (
        <div className="contFinish">
            <div className="windowFinish">
                <p className="textFinish" style={{fontSize:"30px"}}>Конец</p>
                <p className="textFinish">Твой путь бизнесмена окончен!<br/><br/>Самый большой из твоих активаов - {maxItem}!<br/><br/>Попробуй заново, и ты обязательно достигнешь еще больших высот! <br/><br/>Не забудь поделитсья результатом с друзьями!</p>
                <div className="btnFinish" onClick={newGame} style={{marginBottom:"20px"}}>
                  <p>Начать с начала</p>
                </div>
                <div className="btnFinish" style={{backgroundColor:"#31791b"}} onClick={()=>VkShare(maxItem, 2)} >
                    <p style={{color:"#FFD700"}}>Поделиться</p>
                </div>
            </div>
        </div>
    )
}

export default FinishScreen