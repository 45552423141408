
import './StartScreen.css'
import { useState } from 'react'
import oligarh from './image/oligarh.png'
import oligarhRight from './image/oligarhRight.png'

const StartScreen = props => {

    const [countScreenH, setCountScreenH] = useState(0)
    const clickScreen = () => {
        props.countScreen()
        setCountScreenH(countScreenH+1)
    }

    const closeScreen = () => {
        setCountScreenH(null)
        props.closeStartScreen()
    }


    return (
        <div className="contStart" onClick={countScreenH===6 ? closeScreen: clickScreen}>
            {countScreenH!==2 ? <img src={oligarh} alt="?" style={{position:"absolute", bottom:"0px", left:"-130px", width:"350px", height:"350px", zIndex:"1000000000", pointerEvents:"none"}}/>:
            <img src={oligarhRight} alt="?" style={{position:"absolute", bottom:"0px", right:"-130px", width:"350px", height:"350px", zIndex:"1000000000", pointerEvents:"none"}}/>
    }
            {countScreenH===0 && 
                <div className="windowStart" onClick={clickScreen}>
                    <p className="pStart" style={{fontSize:"28px"}}>Добро пожаловать в игру<br/>"Миллионер"</p>
                    <br/>
                    <p className="pStart">Построй бизнес-империю, и стань богатейшим человеком во вселенной!!!</p>
                    <br/>
                    <p className="pStart">Дальше расскажу как и что здесь работает:</p>
                    {/* <img src={oligarh} alt="?" style={{position:"absolute", top:"240px", left:"-130px", width:"350px", height:"350px"}}/> */}
                </div>
            }
            {countScreenH===1 && 
                <div className="windowStart2" onClick={clickScreen} >
                    <p className="pStart">Здесь появляются ресурсы, размещай их на поле по 3 рядом друг с другом чтобы получить ресурс большей стоимости. <br/><br/>Нередко попадаются персонажи которые могут как помочь тебе, так и навредить.</p>
                    {/* <img src={oligarh} alt="?" style={{position:"absolute", top:"240px", left:"-130px", width:"350px", height:"350px"}}/> */}
                </div>
            }
            {countScreenH===2 && 
                <div className="windowStart2s" onClick={clickScreen} >
                    <p className="pStart">В сейф можно спрятать на некоторое время любой ресурс или персонажа и воспользоваться им когда он станет необходим.</p>
                    {/* <img src={oligarh} alt="?" style={{position:"absolute", rotate:"-45deg", top:"100px", right:"-130px", width:"260px", height:"260px"}}/> */}
                </div>
            }
            {countScreenH===3 && 
                <div className="windowStart3" onClick={clickScreen} >
                    <p className="pStart">На бирже можно купить все что душе угодно, главное чтобы хватило денег!</p>
                    {/* <img src={oligarh} alt="?" style={{position:"absolute", rotate:"45deg", top:"100px", left:"-130px", width:"260px", height:"260px"}}/> */}
                </div>
            }
            {countScreenH===4 && 
                <div className="windowStart3s" onClick={clickScreen} style={{marginBottom:"400px"}}>
                    <p className="pStart">Кстати о деньгах, на старте у тебя всего 5000 монет. Строй бизнес, копи имущество и получай больше монет!</p>
                    {/* <img src={oligarh} alt="?" style={{position:"absolute", rotate:"-45deg", top:"100px", right:"-130px", width:"260px", height:"260px"}}/> */}
                </div>
            }
            {countScreenH===5 && 
                <div className="windowStart4" onClick={clickScreen} style={{marginBottom:"180px"}}>
                    <p className="pStart">Юрист - твой лучший помощник, он может переместить находящиеся на поле имущество на любое свободное место, для этого передвинь юриста на нужный ресурс. Воспользоваться его помощью можно один раз за 100 ходов.</p>
                    {/* <img src={oligarh} alt="?" style={{position:"absolute", rotate:"45deg", top:"140px", left:"-130px", width:"260px", height:"260px"}}/> */}
                </div>
            }
            {countScreenH===6 && 
                <div className="windowStart5" onClick={closeScreen} style={{marginBottom:"200px"}}>
                    <p className="pStart">Это все что тебе нужно знать для строительства бизнес-империи. <br/>Если остались вопросы, здесь можно посмотреть всю интересующую информацию. <br/>Удачи!</p>
                    {/* <img src={oligarh} alt="?" style={{position:"absolute", rotate:"-45deg", top:"140px", right:"-130px", width:"260px", height:"260px"}}/> */}
                </div>
            }
            <div className="contSkip" onClick={closeScreen}>
                <p className="skip" >Пропустить</p>
            </div>
        </div>
    )
}

export default StartScreen