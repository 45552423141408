import './Jail.css'
import lowyerImg from './pogony/lowyer.png'
import {useState } from 'react'

const Lowyer = props => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);

    const handleTouchStart = (e) => {
        props.lowyer()
    
        const touch = e.touches[0];
        setPosition({ x: touch.clientX, y: touch.clientY});
        setDragging(true);
      };
    
      const handleTouchMove = (e) => {
        if (dragging) {
            const touch = e.touches[0];
            const adjustedX=touch.clientX
            const adjustedY=touch.clientY
            setPosition({ x: adjustedX-40, y: adjustedY-30});
        }
      };
    
      const handleTouchEnd = (e) => {
        if (dragging) {
            const touch = e.changedTouches[0];
            const adjustedX=touch.clientX
            const adjustedY=touch.clientY
            props.handleTouchEnd2(e,adjustedX ,adjustedY-70);
            setDragging(false);
        }
      };
    return (
        <div className="containerLowyer" style={{zIndex:props.screenTap===5 ? "10000000":""}}>
            <p className="lowyerP">{100-props.countLow}</p>
            <img 
            src={lowyerImg} 
            alt="?"
            onTouchStart={props.availLow && props.screenTap!==5 ? handleTouchStart:null}
            onTouchMove={props.availLow ? handleTouchMove: null}
            onTouchEnd={props.availLow ? handleTouchEnd: null}
            className={dragging ? "contJailAnime": undefined} 
            style={{
                opacity:!props.availLow && "0.5",
                width: dragging ? "60px": '30px',
                height: dragging ? "60px": '30px',
                position: dragging && 'absolute',
                left: position.x,
                top: position.y-70,
                touchAction: 'none',
                cursor: dragging ? 'grabbing' : 'grab',
                zIndex:3
            }}
            />
        </div>
    )
}

export default Lowyer