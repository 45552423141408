import { useEffect } from "react";
import p0 from'./pogony/p0.png'
import p1 from'./pogony/p1.png'
import p2 from'./pogony/p2.png'
import p3 from'./pogony/p3.png'
import p4 from'./pogony/p4.png'
import p5 from'./pogony/p5.png'
import p6 from'./pogony/p6.png'
import p7 from'./pogony/p7.png'
import p8 from'./pogony/p8.png'
import p9 from'./pogony/p9.png'
import p10 from'./pogony/p10.png'
import p11 from'./pogony/p11.png'
import p100 from'./pogony/p100.png'
import p300 from'./pogony/p300.png'
import p1000 from'./pogony/p1000.png'
import p1001 from'./pogony/p1001.png'
import p1002 from'./pogony/p1002.png'
import p1003 from'./pogony/p1003.png'
import p1004 from'./pogony/p1004.png'
import lock from "./image/lock.png"
import quiz from './image/quizWhite.png'
import topWall from './image/topWall.JPG'
import grass from './image/grass1.JPG'
import money from './image/money.jpeg'
import oligarh from './image/oligarh.png'
import oligarhRight from './image/oligarhRight.png'

const PreloadImages = () => {
    useEffect(() => {
      const images = [p0, p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11,p100, p300, p1000, p1001, p1002, p1003, p1004, lock, quiz, topWall, grass, money, oligarh, oligarhRight ]; // Добавьте все изображения в массив
      images.forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    }, []);
  
    return null; // Этот компонент не рендерит ничего
  };

  export default PreloadImages