import bridge from '@vkontakte/vk-bridge';
// import { useEffect } from 'react';

const VkShare = (maxItem, number, func, maxValue) => {
  // console.log(maxItem)

  const setVk = () => {
    const valueItem = JSON.stringify(maxValue)
    bridge.send('VKWebAppStorageSet', {
        key: "maxShare",
        value: valueItem
       })
       .then((data) => { 
        if (data.result) {
            // console.log("Данные записались1")
            // console.log(data.result)
        }
      })
      .catch((error) => {
        // Ошибка
        console.log(error);
      });
  }

  let message = ""
  if (number === 1) {
    message=`В моей бизнес империи уже ${maxItem}! Сможешь меня обогнать?`
  }
  if (number ===2) {
    message=`Моя бизнес империя построена, у меня в активах есть ${maxItem}! Сможешь меня обогнать?`
  }

  bridge.send('VKWebAppShowWallPostBox', {
    message: message,
    attachment: 'https://m.vk.com/games/app51926564',
    // owner_id: 743784474
  })
  .then( (data) => {
    func(maxValue)
    setVk()
    // Запись отправлена на стену
    // console.log(`Идентификатор записи: ${data.post_id}`);
  })
  .catch( (e) => {
    // console.log("Ошибка!", e);
  })

return 

}

export default VkShare