import { useState, useEffect} from 'react'
import './Start.css'
import VkInvite from './VkInvite.js'
import invite from './image/inviteGold.png'
import Loading from './Loading.js'
import playGold from './image/playW3.png'
import soundOn from './image/soundOn.png'
import redLine from './image/redLine.png'
// import get from './sounds/get.mp3'
import logo from './image/logo.png'
import add from './image/add.png'
import p0 from './pogony/p0.png'
import blik from './image/blik.png'
import table from './image/table.png'

import VkHomeScreen from './VkHomeScreen.js'
import TableLiders from './TableLiders'


const Start = props => {
    const [visLoading, setVisLoading] = useState(true)
    // видимость таблицы лидеров
    const [visTable, setVisTable] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setVisLoading(false)
        }, 5000)
    }, [])

    const close = () => {
        props.closeStart()
    }
    const mute = () => {
        props.getMute()
        props.stopSoundBack()
    }

    const playSound = () => {
        props.playSoundBack()
        //   const audio = document.getElementById('get');
        //   audio.play(); 
        } 

    // const handleMuteClick = () => {
    //     if (props.mute && audioRef.current) {
    //         audioRef.current.play() 
    //     }
    //     props.getMute()
    // }
    const addApp = () => {
        VkHomeScreen()
    }
    const getVisTable = () => {
        setVisTable(!visTable)
    }

    return (
        <div className="contStart1">
            <img src={p0} alt="0" className="fallCoin"/>
            <img src={p0} alt="0" className="fallCoin" style={{animationDuration:"4s"}}/>
            <img src={p0} alt="0" className="fallCoin1"/>
            <img src={p0} alt="0" className="fallCoin1" style={{animationDuration:"3s"}}/>
            <img src={p0} alt="0" className="fallCoin2"/>
            <img src={p0} alt="0" className="fallCoin2" style={{animationDuration:"4.5s"}}/>
            <img src={p0} alt="0" className="fallCoin3"/>
            <img src={p0} alt="0" className="fallCoin3" style={{animationDuration:"3.5s"}}/>
            <img src={p0} alt="0" className="fallCoin4"/>
            <img src={p0} alt="0" className="fallCoin4" style={{animationDuration:"4s"}}/>
            <img src={p0} alt="0" className="fallCoin5"/>
            <img src={p0} alt="0" className="fallCoin" style={{animationDuration:"3s"}}/>
            {visLoading && <Loading />}
                <div className="contHead">
                    <p className="name">МИЛЛИ<img src={logo} alt="logo" className="logo"/>НЕР</p>
                    <p className="slogan">ПОСТРОЙ БИЗНЕС-ИМПЕРИЮ</p>
                </div>
            <div >
                <img src={playGold} alt='' className='btnPlay' onClick={close} />
            </div>
            <div style={{position:"relative", marginBottom: "50px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    {/* <div className="btn" style={{ position:"absolute",top:"-80px", left:"40px", }} onClick={() => VkHomeScreen()}>
                        <img src={add} alt="1" className="iconBtn"  />
                    </div> */}
                <div className="btn" style={{ marginLeft: "40px", position:"relative" }} onClick={() => VkInvite()}>
                    <p className="textUp">ПРИГЛАСИТЬ</p>
                <img src={blik} alt="0" style={{position:"absolute", bottom:"-2px", right:"-2px", width:"20px", height:"20px", pointerEvents:"none", opacity:"0.7"}} />
                    <img src={invite} alt="1" className="iconBtn"  />
                 </div>
                 <div className="btn" style={{position:"relative", marginLeft:"1px"}} onClick={getVisTable}>
                 <p className="textUp">ЛИДЕРЫ</p>
                 <img src={blik} alt="0" style={{position:"absolute", bottom:"-2px", right:"-2px", width:"20px", height:"20px", pointerEvents:"none", opacity:"0.7"}} />
                        <img src={table} alt="1" className="iconBtn"  />
                    </div>

                    <div className="btn" style={{position:"relative", marginLeft:"1px"}} onClick={addApp}>
                        <p className="textUp">ДОБАВИТЬ</p>
                         <img src={blik} alt="0" style={{position:"absolute", bottom:"-2px", right:"-2px", width:"20px", height:"20px", pointerEvents:"none", opacity:"0.7"}} />
                        <img src={add} alt="1" className="iconBtn"  />
                    </div>
                {props.mute ?
                    <div className="btn" style={{ marginRight: "40px", position: "relative" }} onClick={()=> {mute(); playSound()}}>
                        <p className="textUp" style={{fontSize:"10px"}}>ЗВ.ВЫКЛ</p>
                        <img src={blik} alt="0" style={{position:"absolute", bottom:"-2px", right:"-2px", width:"20px", height:"20px", pointerEvents:"none", opacity:"0.7"}} />
                        <img src={soundOn} alt="1" className="iconBtn" />
                        <img src={redLine} style={{ position: "absolute", width: "50px", height: "50px" }} alt="sound" />
                    </div> :
                    <div className="btn" style={{ marginRight: "40px", position: "relative" }} onClick={mute}>
                        <p className="textUp">ЗВ.ВКЛ</p>
                        <img src={blik} alt="0" style={{position:"absolute", bottom:"-2px", right:"-2px", width:"20px", height:"20px", pointerEvents:"none", opacity:"0.7"}} />
                        <img src={soundOn} alt="1" className="iconBtn"  />
                    </div>
                }
                {/* <div className="btn" onClick={() => VkInvite()}>
                    <img src={invite} alt="1" style={{ width: "40px", height: "40px" }} />
                </div> */}
            </div>
            {visTable && <TableLiders getVisTable={getVisTable}/>}
        </div>
    )
}

export default Start
