import bridge from '@vkontakte/vk-bridge';

const GetIntro = (gorizNum, number, who, gameStateField, getArrIntro)=> {
    // console.log("Запуск Гет интро")
    let arrNumbers = gameStateField

    // console.log("Это сохраненный массив")
    // console.log(arrNumbers)
    let maxValue = 0;
    //Проверяем из рандомного генератора
    const writeRandom = () => {
        //Если вызываем при ходе, не срабатывает
        if (who!==2) {
            // console.log("Вызов интор1")
            maxValue=number
            arrNumbers.push(number)
            getArrIntro(arrNumbers)
            const valueArr = JSON.stringify(arrNumbers)
            bridge.send('VKWebAppStorageSet', {
                key: "arrIntro",
                value: valueArr
               })
               .then((data) => { 
                if (data.result) {
                    // console.log("Данные записались1")
                    // console.log(data.result)
                }
              })
              .catch((error) => {
                // Ошибка
                console.log(error);
              });
        }
    }

    const writeCell = () => {
        //Если вызваем с рандома не срабатывает
        if (who!==1) {
        for (let i = 0; i < gorizNum.length; i++) {
            for (let j = 0; j < gorizNum[i].length; j++) {
                if (maxValue >= gorizNum[i][j]) {
                } else {
                    if (!arrNumbers.includes(gorizNum[i][j])) {
                        // console.log("Вызов интор2")
                        maxValue = gorizNum[i][j];
                        arrNumbers.push(gorizNum[i][j]);
                        getArrIntro(arrNumbers)
                        const valueArr = JSON.stringify(arrNumbers)
                        bridge.send('VKWebAppStorageSet', {
                            key: "arrIntro",
                            value: valueArr
                           })
                           .then((data) => { 
                            if (data.result) {
                                // console.log("Данные записались2")
                                // console.log(data.result)
                            }
                          })
                          .catch((error) => {
                            // Ошибка
                            console.log(error);
                          });
                    } else {

                    }
                }
                }
            }
        }

    }
    


    if ((number === 100|| number === 1000 || number === 300) && !arrNumbers.includes(number) ) {
        writeRandom()
    } else {
        writeCell()
    }

    return maxValue

}

export default GetIntro