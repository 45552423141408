import { initializeApp } from 'firebase/app';
import {doc, getDoc, getFirestore } from 'firebase/firestore'; 
import firebaseConfig from './firebaseConfig.js';

const GetFire = async (docId) => {
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    const fetchDocument = async (docId) => {
        try {
            // Получаем ссылку на документ с определенным ID
            const docRef = doc(db, 'buyer', docId);
            // Извлекаем данные документа
            const docSnap = await getDoc(docRef);
        
            if (docSnap.exists()) {
                // Если документ существует, возвращаем данные
                const data = docSnap.data();
                // console.log('Данные документа:', { id: docSnap.id, ...data });
                return data;
            } else {
                // Если документа не существует
                // console.log('Документ не найден');
                return null;
            }
        } catch (error) {
            console.error('Ошибка при извлечении данных:', error);
            return null;
        }
    };

    return fetchDocument(docId);
};

export default GetFire;

