import './FinishScreen.css'
import CleanStorage from './CleanStorage.js'


const NewGame = props => {

    const newGameBtn = () => {
        CleanStorage()
    }

    const close = () => {
        props.closeNewGame()
    }

    return (
        <div className="contFinish" style={{zIndex:"10010"}}>
        <div className="windowFinish">
            <p className="textFinish" style={{fontSize:"30px"}}>Начать с начала?</p>
            <p className="textFinish">Все активы будут переданы в доход государства, ты начнешь все с нуля со стартовым бюджетом.</p>
            <div className="btnFinish" style={{marginBottom:"20px", height:"20px"}} onClick={newGameBtn}>
              <p>Начать заново</p>
            </div>
            <div className="btnFinish" style={{backgroundColor:"#31791b"}} onClick={close}>
                <p style={{color:"#FFD700"}}>Отменить</p>
            </div>
        </div>
    </div>
  
    )
}

export default NewGame