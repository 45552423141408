import { initializeApp } from 'firebase/app';
import {getFirestore } from 'firebase/firestore'; 
import firebaseConfig from './firebaseConfig.js';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';

const GetScore = async (docId) => {
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    const fetchTopScores = async () => {
        try {
          const q = query(
            collection(db, "buyer"), // имя вашей коллекции
            orderBy("score", "desc"),
            limit(10)
          );
  
          const querySnapshot = await getDocs(q);
          const scores = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
        //   console.log(scores)
          return(scores);
        } catch (error) {
          console.error("Error fetching top scores: ", error);
          return null
        }
      };

    return fetchTopScores();
};

export default GetScore;

