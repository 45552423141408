import bridge from '@vkontakte/vk-bridge';
import SentFire from './SentFire';

let getItem =""

const WindowVkBuy = (item, func, close) => {
  getItem = item
  console.log("Нажали на покупку")
  bridge.send('VKWebAppShowOrderBox', 
  { 
    type: 'item', 
    item: item, 
  })
  .then( (data) => {
    console.log('Покупка состоялась.', data);
    func()
    getId()
    close(1)
  }) 
  .catch( (e) => {
    console.log('Ошибка!', e);
    close(2)
  });

return 

}
const getId = () => {
// Получение информации о пользователе
bridge.send('VKWebAppGetUserInfo')
  .then(data => {
    // ID пользователя
    const userId = data.id.toString()
    console.log(userId)

    let buy =''
    if (getItem ==="steps"){
      buy = {
        steps: true
      }
      SentFire(userId, buy)
    } else if (getItem === "raider") {
      buy = {
        raider: true
      }
      SentFire(userId, buy)
    }

    return userId;
  })
  .catch(error => {
    console.error('Error getting user info:', error);
  });
}


export default WindowVkBuy