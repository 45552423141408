import { initializeApp } from 'firebase/app';
import { doc, getDoc, setDoc, getFirestore } from 'firebase/firestore'; 
import firebaseConfig from './firebaseConfig.js';

export default async function SentFire(id, newData) {

    if (typeof id !== 'string') {
        throw new TypeError('ID must be a string');
    }

    if (typeof newData !== 'object') {
        throw new TypeError('newData must be an object');
    }

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    // Запись в базу данных Firebase
    const handleSave = async () => {
        try {
            const docRef = doc(db, "buyer", id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                console.log("документ существует")
                // Если документ существует, обновляем его, сохраняя старые значения
                const oldData = docSnap.data();
                const updatedData = { ...oldData, ...newData };
                await setDoc(docRef, updatedData);
            } else {
                // Если документа не существует, создаём новый
                await setDoc(docRef, newData);
            }

            console.log('Документ успешно сохранён/обновлён!');
        } catch (error) {
            console.error('Ошибка при добавлении/обновлении документа:', error);
        }
    }

    await handleSave();
}
