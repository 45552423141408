import {useState } from 'react'
import './Jail.css'
import p1 from'./pogony/p1.png'
import p2 from'./pogony/p2.png'
import p3 from'./pogony/p3.png'
import p4 from'./pogony/p4.png'
import p5 from'./pogony/p5.png'
import p6 from'./pogony/p5.png'
import p7 from'./pogony/p7.png'
import p8 from'./pogony/p8.png'
import p9 from'./pogony/p9.png'
import p10 from'./pogony/p10.png'
import p11 from'./pogony/p11.png'
import p12 from'./pogony/p12.png'
import p13 from'./pogony/p13.png'
import p100 from'./pogony/p100.png'
import p300 from'./pogony/p300.png'
import p1000 from'./pogony/p1000.png'
import p1001 from'./pogony/p1001.png'
import p1002 from'./pogony/p1002.png'
import p1003 from'./pogony/p1003.png'
import p1004 from'./pogony/p1004.png'
import jailImg from '../democracy/image/seif.png'
import quiz from './image/quizWhite.png'

const Jail = props => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);

    const handleTouchStart = (e) => {
        if (props.numberJail) {
        props.getJail()
    }
        const touch = e.touches[0];
        setPosition({ x: touch.clientX-40, y: touch.clientY-40 });
        setDragging(true);
      };
    
      const handleTouchMove = (e) => {
        if (dragging) {
            const touch = e.touches[0];
            const adjustedX=touch.clientX
            const adjustedY=touch.clientY
            props.handleTouchMove2(e,adjustedX ,adjustedY-70)
            setPosition({ x: adjustedX-40, y: adjustedY-30});
        }
      };
    
      const handleTouchEnd = (e) => {
        if (dragging) {
            const touch = e.changedTouches[0];
            const adjustedX=touch.clientX
            const adjustedY=touch.clientY
            props.handleTouchEnd2(e,adjustedX ,adjustedY-70);
            setDragging(false);
        }
      };

    return (
        <div
        className="containerJail" 
        src={jailImg}
        alt="X"
        data-row-index={100}
        data-col-index={100}
        style={{
            position:props.screenTap===2 ?"relative":"",
            zIndex: props.screenTap===2 ? "100000":"",
            backgroundImage: `url(${jailImg})`, 
            backgroundSize:"contain"
        }}
        >
            {props.numberJail && 
            <img  
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            className={dragging ? "contJailAnime":""} 
            data-row-index={100}
            data-col-index={100}
            src={props.numberJail===300 ? p300: props.numberJail===1 ? p1: props.numberJail===2 ? p2 : props.numberJail===3 ? p3 : props.numberJail===4 ? p4 : props.numberJail===5 ? p5 : props.numberJail===6 ? p6 : props.numberJail===7 ? p7 : props.numberJail===8 ? p8 : props.numberJail===9 ? p9 : props.numberJail===10 ? p10 : props.numberJail===10 ? p11 : props.numberJail===12 ? p12 : props.numberJail===13 ? p13 : props.numberJail===100 ? p100 : props.numberJail===1000 ? p1000 :props.numberJail===1001 ? p1001: props.numberJail===1002 ? p1002: props.numberJail===1003 ? p1003: props.numberJail===1004 ? p1004: quiz} alt={props.numberJail} 
            style={{
                marginLeft: dragging ? "0px": "38px",
                width: dragging ? "60px": '60px',
                height: dragging ? "60px": '60px',
                position: dragging ? 'absolute':null,
                left: position.x,
                top: position.y-70,
                touchAction: 'none',
                cursor: dragging ? 'grabbing' : 'grab',
                // zIndex: dragging ? 1: -1
            }}
            />}
        </div>
    )
}

export default Jail