import './Points.css'
import Lowyer from "./Lowyer"
import stepBack from './image/backWhite.png'
import quiz from './image/quizWhite.png'
import menu from './image/menuWhite.png'
import p0 from './pogony/p0.png'


const Points = props => {

    return (

        <div className="contPoints">
            <Lowyer
                availLow={props.availLow} 
                lowyer={props.lowyer} 
                handleTouchEnd2={props.handleTouchEnd2}
                countLow={props.countLow}
                screenTap={props.screenTap}
            />
            <div style={{opacity: props.backVis && props.gold>1499? 1: 0.9}} className="eachPoint" onClick={()=> props.backVis && props.gold>1499? props.stepBack():null}>
                <img src={stepBack} alt="<" className="pointImg" style={{opacity: props.backVis && props.gold>1499? 1: 0.3}}/>
                <div style={{display:"flex", 
                    flexDirection:"column", 
                    alignItems:"center", 
                    justifyContent:"center",
                    opacity: props.backVis && props.gold>1499? 1: 0.3
                    }}>
                    <img src={p0} alt="o" style={{width:"15px", height:"15px", margin:"0", backgroundColor:"white", borderRadius:"50%"}}/>
                    <p className="pPoints">1500</p>
                </div>
            </div>
            <div className="eachPoint" onClick={()=>{props.screenTap!==6 && props.openInfo()}} 
            style={{
                position: props.screenTap===6 ? "relative":"",
                zIndex:props.screenTap===6 ? "1000000":""
            }}>
            <img src={quiz} alt="<" className="pointImg"/>
            </div>
            <div className="eachPoint" onClick={()=>props.openModalDem()}>
            <img src={menu} alt="<" className="pointImg"/>
            </div>

        </div>
    )
}

export default Points