import bridge from '@vkontakte/vk-bridge';

const CleanStorage = () => {
  setTimeout(()=>{window.location.reload()},300)

    bridge.send('VKWebAppStorageSet', {
      key: 'vkKeys'})
     .then((data) => { 
       if (data.result) {
         // Значение переменной задано
       }
     })
     .catch((error) => {
       // Ошибка
       console.log(error);
     });
}

export default CleanStorage