import React, { useState } from 'react';
import "./CubeDem.css"

import p1 from'./pogony/p1.png'
import p2 from'./pogony/p2.png'
import p3 from'./pogony/p3.png'
import p4 from'./pogony/p4.png'
import p5 from'./pogony/p5.png'
import p6 from'./pogony/p6.png'
import p7 from'./pogony/p7.png'
import p8 from'./pogony/p8.png'
import p9 from'./pogony/p9.png'
import p10 from'./pogony/p10.png'
import p11 from'./pogony/p11.png'
import p12 from'./pogony/p12.png'
import p13 from'./pogony/p13.png'
import p100 from'./pogony/p100.png'
import p300 from'./pogony/p300.png'
import p1000 from'./pogony/p1000.png'
import p1001 from'./pogony/p1001.png'
import p1002 from'./pogony/p1002.png'
import p1003 from'./pogony/p1003.png'
import p1004 from'./pogony/p1004.png'
import quiz from './image/quizWhite.png'
// import p1001 from'./pogony/p1001.png'
// import lowyerImg from './pogony/lower.png'

const TestDrag = props => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);


  const handleTouchStart = (e) => {
    // props.playSound("choose")
    props.clickNumber()
    const touch = e.touches[0];
    setPosition({ x: touch.clientX-40, y: touch.clientY-40 });
    setDragging(true);
  };

  const handleTouchMove = (e) => {
    if (dragging) {
      const touch = e.touches[0];
      const adjustedX=touch.clientX
      const adjustedY=touch.clientY
      props.handleTouchMove2(e,adjustedX ,adjustedY-70)
      setPosition({ x: adjustedX-40, y: adjustedY-100});
    }
  };

  const handleTouchEnd = (e) => {
    if (dragging) {
      const touch = e.changedTouches[0];
      const adjustedX=touch.clientX
      const adjustedY=touch.clientY
      props.handleTouchEnd2(e,adjustedX ,adjustedY-70);
      setDragging(false);
    }
  };
  

  return (
    <div className="contDrag" style={{zIndex: props.screenTap===1 ? "100000": ""}}>
      <p>{props.indRow}</p>
      {props.chooseNum &&
      <img  src={props.chooseNum===300 ? p300 : props.chooseNum===100 ? p100: props.chooseNum===1 ? p1: props.chooseNum===2 ? p2 : props.chooseNum===3 ? p3 : props.chooseNum===4 ? p4 : props.chooseNum===5 ? p5 : props.chooseNum===6 ? p6 : props.chooseNum===7 ? p7 : props.chooseNum===8 ? p8 : props.chooseNum===9 ? p9 : props.chooseNum===10 ? p10 : props.chooseNum===11 ? p11 : props.chooseNum===12 ? p12 : props.chooseNum===13 ? p13 : props.chooseNum===1000 ? p1000: props.chooseNum===1001 ? p1001: props.chooseNum===1002 ? p1002: props.chooseNum===1003 ? p1003: props.chooseNum===1004 ? p1004: quiz  } alt={props.chooseNum} 
            className={dragging ? "imgNumberAnime": undefined}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            style={{
              width: dragging ? "60px": '60px',
              height: dragging ? "60px": '60px',
              position: dragging && 'absolute',
              left: position.x,
              top: position.y,
              touchAction: 'none',
              cursor: dragging ? 'grabbing' : 'grab',
              // pointerEvents: "none"
            }}/>
          }
    </div>
  );
};

export default TestDrag;
