import './ModalShop.css'

import p0 from './pogony/p0.png'
import p1 from'./pogony/p1.png'
import p2 from'./pogony/p2.png'
import p3 from'./pogony/p3.png'
import p4 from'./pogony/p4.png'
import p100 from'./pogony/p100.png'
import p300 from'./pogony/p300.png'
import p1000 from'./pogony/p1000.png'
import closeShop from './image/goldClose.png'

// import SendVkInfo from './SendVkInfo.js'

import lowyer from './image/lowyer.png'
import step from './image/step.png'

const ModalShop = props => {

    const close = () => {
        props.closeShop()
    }
    const buyItem = (number, count) => {
        props.buyItem(number, count)
    }

    return (
        <div className="contModalShop">
        <div className="windowModalShop" >
            <img src={closeShop} alt="X" style={{width:"60px", height:"60px", position:"absolute", top:"-22px", right:"-22px", borderRadius:"50%"}} onClick={close}/>
            <div className="shopHead">
            <div className="contPShop" >
                 <p className="pShop">Б И Р Ж А</p>
            </div>
            </div>
            <div className="twoBlocks">
                <div className="block" onClick={()=> props.gold>150 && buyItem(1,150)}>
                {props.gold<150 && <div className="coverBlock" />}
                <div className="contTextShop">
                    <img src={p0} alt="0" className="imgCoin"/>
                    <p className="imgText">150</p>
                </div>
                    <img src={p1} className='imgShop' alt="1"/>
                    <img src={p1} className='imgShop2' alt="1"/>
                </div>
                <div className="block" onClick={()=> props.gold>450 && buyItem(2,450)}>
                {props.gold<450 && <div className="coverBlock" />}
                <div className="contTextShop">
                    <img src={p0} alt="0" className="imgCoin"/>
                    <p className="imgText">450</p>
                </div>
                    <img src={p2} className='imgShop' alt="1"/>
                    <img src={p2} className='imgShop2' alt="1"/>
                </div>
            </div>
            <div className="twoBlocks">
                <div className="block" onClick={()=> props.gold>1200 && buyItem(3, 1200)}>
                {props.gold<1200 && <div className="coverBlock" />}
                <div className="contTextShop">
                    <img src={p0} alt="0" className="imgCoin"/>
                     <p className="imgText">1200</p>
                </div>
                    <img src={p3} className='imgShop' alt="1"/>
                    <img src={p3} className='imgShop2' alt="1"/>
                </div>
                <div className="block" onClick={()=> props.gold>2500 && buyItem(4,2500)}>
                {props.gold<2500 && <div className="coverBlock" />}
                <div className="contTextShop">
                    <img src={p0} alt="0" className="imgCoin"/>
                     <p className="imgText">2500</p>
                </div>
                    <img src={p4} className='imgShop' alt="1"/>
                    <img src={p4} className='imgShop2' alt="1"/>
                </div>
            </div>
            <div className="twoBlocks">
                <div className="block" onClick={()=> props.gold>3000 && buyItem(100, 3000)} >
                {props.gold<3000 && <div className="coverBlock" />}
                <div className="contTextShop" >
                    <img src={p0} alt="0" className="imgCoin" />
                    <p className="imgText">3000</p>
                </div>
                    <img src={p100} className='imgShop' alt="1" />
                    <img src={p100} className='imgShop2' alt="1" />
                </div>
                <div className="block"  onClick={()=> props.gold>4500 && buyItem(1000,4500)}>
                {props.gold<3000 && <div className="coverBlock" />}
                <div className="contTextShop">
                    <img src={p0} alt="0" className="imgCoin"/>
                     <p className="imgText">4500</p>
                </div>
                    <img src={p1000} className='imgShop' alt="1"/>
                    <img src={p1000} className='imgShop2' alt="1"/>
                </div>
            </div>
            <div className="twoBlocks">
                <div className="block" onClick={()=>!props.availLow && props.getNumberBuy(1)} >
                {props.availLow && <div className="coverBlock" />}
                <div className="contTextShop">
                    <p className="imgText" >юрист</p>
                </div>
                    <img src={lowyer} className='imgShop' alt="1"/>
                    <img src={lowyer} className='imgShop2' alt="1"/>
                </div>
                <div className="block" onClick={()=> props.step <1000 && props.getNumberBuy(2)}>
                {props.step >1000 && <div className="coverBlock" />}    
                <div className="contTextShop">
                    {/* <img src={p0} alt="0" className="imgCoin"/> */}
                     <p className="imgText">ходы</p>
                </div>
                    <img src={step} className='imgShop' alt="1"/>
                    <img src={step} className='imgShop2' alt="1"/>
                </div>
            </div>
            <div className="twoBlocks">
                <div className="block" onClick={()=>!props.cancelRaider && props.getNumberBuy(3)}>
                {props.cancelRaider && <div className="coverBlock" />}
                <div className="contTextShop">
                    {/* <img src={p0} alt="0" className="imgCoin"/> */}
                    <p className="imgText" >убрать</p>
                </div>
                    <img src={p300} className='imgShop' alt="1"/>
                    <img src={p300} className='imgShop2' alt="1"/>
                </div>
                <div className="block" onClick={()=>props.getNumberBuy(4)}>
                <div className="contTextShop">
                    {/* <img src={p0} alt="0" className="imgCoin"/> */}
                     <p className="imgText">деньги</p>
                </div>
                    <img src={p0} className='imgShop' alt="1"/>
                    <img src={p0} className='imgShop2' alt="1"/>
                </div>
            </div>

        </div>
        </div>
    )
}
export default ModalShop
