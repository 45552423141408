import "./Introduce.css"
// import lowyer from "./image/lowyer.png"
import p0 from './pogony/p0.png'
import p1 from'./pogony/p1.png'
import p2 from'./pogony/p2.png'
import p3 from'./pogony/p3.png'
import p4 from'./pogony/p4.png'
import p5 from'./pogony/p5.png'
import p6 from'./pogony/p6.png'
import p7 from'./pogony/p7.png'
import p8 from'./pogony/p8.png'
import p9 from'./pogony/p9.png'
import p10 from'./pogony/p10.png'
import p11 from'./pogony/p11.png'
import p12 from'./pogony/p12.png'
import p13 from'./pogony/p13.png'
import p100 from './pogony/p100.png'
import p300 from './pogony/p300.png'
import p1000 from './pogony/p1000.png'
import p1001 from'./pogony/p1001.png'
import p1002 from'./pogony/p1002.png'
import p1003 from'./pogony/p1003.png'
import p1004 from'./pogony/p1004.png'


import close from './image/goldClose.png'
import oligarh from './image/oligarh.png'
import share from './image/shareGold.png'

import VkShare from "./VkShare.js"

const Introduce = props => {


    const arrImg = {
        1: {
            img: p1,
            text:"Копейка рубль бережет! Монетка к моенетке, а там и на серебро хватит!"
            },
        2: {
            img: p2,
            text:"Серебряная монета - большое начало! Три в копилку, и до золота недалеко!"
            },
        3: {
            img: p3,
            text:"А вот и золотишко! Три монетки и на стартовый капитал хватит!"
        },
        4: {
            img:p4,
            text:"Стартовый капитал готов! Осталось собрать побольше и можно начинать бизнес!"
            },
        5: {
            img:p5,
            text:"С малого бизнеса начинаются большие дела! Кафе открыто, ждем первых клиентов!"
            },
        6: {
            img:p6,
            text:"Нанимай продавцов, товар закуплен! Магазинчик к магазинчику а там и на недвижимость хватит!",
            maxItem:"Магазин"
            },
        7: {
            img: p7,
            text:"Неджвижимость - основа бизнеса! Еще пару отелей и можно пробовать свои силы в промышленности!",
            maxItem:"Отель"
        },
        8: {
            img:p8,
            text:"Завод дело хорошее! Но для полноценной работы не помещает электростанция!",
            maxItem:"Завод"
        },
        9: {
            img:p9,
            text:"Все готово для большого бизнеса! Еще пара электростанций и пора подумать о больших деньгах!",
            maxItem:"Электростанция"
        },
        10: {
            img:p10,
            text:"С собственным банком дела пойдут быстрее! Деньги от населения можно пустить на финансирование более масштабных проектов!",
            maxItem:"Банк"
        },
        11: {
            img:p11,
            text:"Собственная железная дорога! Грузовые и пассажирские перевозки по земле, а что если попробовать свои силы в небе!",
            maxItem:"Железная дорога"
        },
        12: {
            img:p12,
            text:"Авиакомпания позволяет осуществлять транспортировки по всему миру! Заработки выше, амбиций больше!",
            maxItem:"Авиакомпания"
        },
        13: {
            img:p13,
            text:"Нефтедобывающая компания - крупнейший бизнес, мировые продажи - миллиардные доходы. Больше вышек - больше возможностей!",
            maxItem:"Нефтедобывающая компания"
        },
        100: {
            img:p100,
            text:"Налоговый агент накладывает арест и продает имущество! Выручка с продажи уходит на оплату налогов, лишь часть достается владельцу."
        },
        300: {
            img:p300,
            text:"Рейдер - коварный субъект, захватывает и блокирует любой актив. Единственный способ избавиться от захваченного актива - сдать его налоговому агенту."
        },
        1000: {
            img:p1000,
            text:"За деньги можно купить всё, магазин отель или даже банк! Деньги превращаются в недостающий актив! Если рядом ничего нет, начнется строительстов игрового бизнеса."
        },
        1001: {
            img:p1001,
            text:"Когда деньги девать некуда, можно начать строить игровой бизнес, который хоть и по копеечке, но стабильно приносит доход!"
        },
        1002: {
            img:p1002,
            text:"Какой игровой бизнес обойдется без однорукого бандита? Доход выше, перспектив больше!"
        },
        1003: {
            img:p1003,
            text:"Что наша жизнь? Игра! Покер - серьезные участники, большие деньги, и лишь один шаг до мечты!"
        },
        1004: {
            img:p1004,
            text:"Казино - предел мечтаний настоящего бизнесмена! Стремиться больше некуда, осталось только получать доход!"
        },
    }
    if (props.number && 
        props.number!==0 &&
        props.number!==301 &&
        props.number!==302 &&
        props.number!==303 &&
        props.number!==304 &&
        props.number!==305 &&
        props.number!==306 &&
        props.number!==307 &&
        props.number!==308 &&
        props.number!==309 &&
        props.number!==310 &&
        props.number!==311 &&
        props.number!==312 &&
        props.number!==313 &&
        props.number!==314 &&
        props.number!==315 &&
        props.number!==1301 &&
        props.number!==1302 &&
        props.number!==1303 &&
        props.number!==1304
        ) {

        const greatFunc = () => {
            VkShare(arrImg[props.number].maxItem, 1, props.inviteGold, props.number)
            setTimeout(()=>{
                props.closeIntro()
            },1000)
        }    

    return (
        <div className="contIntro">
            <img src={oligarh} alt="?" style={{position:"absolute", bottom:"0px", left:"-130px", width:"350px", height:"350px", zIndex:"1000000000", pointerEvents:"none"}}/>
            <div className="windowIntro">
            {/* <img src={oligarh} alt="" style={{width:"260px", height:"260px", position:"absolute", top:"100px", right:"-130px", rotate:"-45deg"}}/> */}
                <img src={close} alt="X" className="introClose" onClick={()=>props.closeIntro()}/>
                <p className="pIntro">{arrImg[props.number].text}</p>
                <img src={arrImg[props.number].img} alt="?" className="imgIntro"/>
                {arrImg[props.number].maxItem &&
                    <div className="btnShare" onClick={greatFunc}>
                    <img src={share} alt="=>" className="imgShare"/>
                    <p className="textShare">Поделиться (+3000<img src={p0} alt="0" className="imgBon"/>)</p>
                </div>
        }
            </div>

            </div>
    )
    } else {
        <div></div>
    }
}

export default Introduce