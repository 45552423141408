import './App.css';
import { useEffect } from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {Field} from './democracy/Field'
import bridge from '@vkontakte/vk-bridge';

function App() {
  useEffect(()=>{
  bridge.send("VKWebAppInit", {});
  bridge.send('VKWebAppCheckNativeAds', { ad_format: 'reward' })
  .then((data) => {
    // console.log("Запрос в VK выполнен успешно");
    if (data.result) {
      // console.log("Реклама загружается...");
      // Дополнительные действия при наличии рекламы.
      console.log("Реклама загружена успешно");
    } else {
      // console.log('Рекламные материалы не найдены.');
    }
  })
  .catch((error) => { 
    console.error("Ошибка при выполнении запроса в VK:", error); 
  });
})

  return (
    <BrowserRouter>
      <Routes>
         <Route path='/' element={<Field/>} />
         <Route path='/catalog' element={<Field/>} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;


