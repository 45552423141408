import './CubeDem.css'
import { useState, useEffect } from 'react'
import bridge from '@vkontakte/vk-bridge';
import Match from './Match'
import Jail from './Jail'
import ModalShop from './ModalShop'
import Score from './Score'
import Points from './Points'
import NumberAnime from './NumberAnime'
import ScoreAnime from './ScoreAnime'
import ModalHint from './ModalHint'
import MenuDem from "./MenuDem"
import Introduce from './Introduce'
import StartScreen from './StartScreen.js'
import FinishScreen from './FinishScreen.js'
import StepsOut from './StepsOut.js'
import VkBuy from './VkBuy.js'

import GetIntro from './GetIntro'
import GetFire from './GetFire.js'
import SentFire from './SentFire.js';

import p1 from'./pogony/p1.png'
import p2 from'./pogony/p2.png'
import p3 from'./pogony/p3.png'
import p4 from'./pogony/p4.png'
import p5 from'./pogony/p5.png'
import p6 from'./pogony/p6.png'
import p7 from'./pogony/p7.png'
import p8 from'./pogony/p8.png'
import p9 from'./pogony/p9.png'
import p10 from'./pogony/p10.png'
import p11 from'./pogony/p11.png'
import p12 from'./pogony/p12.png'
import p13 from'./pogony/p13.png'
// import p300 from'./pogony/p300.png'
// import p1000 from'./pogony/p1000.png'
import p1001 from'./pogony/p1001.png'
import p1002 from'./pogony/p1002.png'
import p1003 from'./pogony/p1003.png'
import p1004 from'./pogony/p1004.png'
import lock from "./image/lock.png"
import quiz from './image/quizWhite.png'
import topWall from './image/topWall.JPG'
import wallMoney from './image/money.jpeg'
import grass1 from './image/grass1.JPG'
// import blik from './image/blik.png'

import TestDrag from './TestDrag'
import Information from './Information'

// import choose from "./sounds/hide.mp3"
import get from './sounds/get.mp3'
import collect from './sounds/collect.mp3'
import lockBlock from './sounds/lockBlock.mp3'
import agentSell from './sounds/agentSell.mp3'
import moneySound from './sounds/money.mp3'
import hide from './sounds/hide.mp3'
import backGroundSound from './sounds/backSound3.mp3'
import PreloadImages from './PreloadImage.js';
import Start from './Start.js';
// import HeadScreen from './HeadScreen.js';

const Field = () => {
  //Покупка
  const [numberBuy, setNumberBuy] = useState(0)

  const getNumberBuy = (number)=> {
    setNumberBuy(number)
  }

    const [gorizNum, setGorizNum] = useState(
        [
        [0,0,0,0,0,0],
        [0,0,0,0,0,0],
        [0,0,0,0,0,0],
        [0,0,0,0,0,0],
        [0,0,0,0,0,0],
        [0,0,0,0,0,0]
    ])
    //Заставка
    const [visStart, setVisStart] = useState(true)
    const closeStart = () => {
      setVisStart(false)
    }

    const [chooseNum, setChooseNum] = useState(1)

    const [myNumber, setMyNumber] = useState(null)

    const [backNum, setBackNum] = useState(gorizNum)
    const [saveNum, setSaveNum] = useState(chooseNum)

    // const [score, setScore] = useState(0);

    const [check, setCheck] = useState(false)

    const [chooseIndA, setChooseIndA] = useState("")
    const [chooseIndB, setChooseIndB] = useState("")

    const [numberJail, setNumberJail] = useState(null)
    //Для шага назад сохраняем номер в тюрьме
    const [saveNumberJail, setSaveNumberJail]= useState(numberJail)
    
    //Флаг если цифра взята из тюрьмы
    const [isJail, setIsJail] = useState(false)

    const [imgNumber, setImgNumber] = useState("imgNumber")

    //золото
    const [gold, setGold] = useState(5000) 

    //Считаем очки
    const [score, setScore] = useState(0)
    const [visAnimeScore, setVisAnimeScore] = useState(false)
    const [localScore, setLocalScore] = useState(null)

    //Видимость магазина
    const [visShop, setVisShop] = useState(false)

    //Ходы
    const [step, setStep] = useState(150)
    const [visStepFinish, setVisStepFinish] = useState(false)

    //Юрист
    const [clickLow, setClickLow] = useState(false)
    const [lowNumber, setLowNumber] = useState(null)

    //Доступность юриста
    const [availLow, setAvailLow] = useState(true)
    const [countLow, setCountLow] = useState(0)

    //Видимость шага назад
    const [backVis, setBackVis] = useState(false)

    //Видимость информации
    const [visInfo, setVisInfo] = useState(false)

    //Видимость подсказки
    const [visHint, setVisHint] = useState(false)
    const [indHint, setIndHint] = useState(null)
    const [indexHint, setIndexHint] = useState(null)

    //Видимость финального экрана
    const [visFinish, setVisFinish] = useState(false)

    //Видимость меню
    const [visMenu, setVisMenu] = useState(false)

    //Убрать рейдера
    const [cancelRaider, setCancelRaider] = useState(false)

    //Видимость Интро
    const [visIntro, setVisIntro] = useState(true)
    const [numberIntro, setNumberIntro] = useState(null)

    const closeIntro = () => {
      setVisIntro(false)
    }

    const [visStartScreen, setVisStartScreen] = useState(true)
    const [screenTap, setScreenTap] = useState(0)

    const closeStartScreen = () => {
      setTimeout(()=>{setScreenTap(0)},50)
      setVisStartScreen(false)
      const buy = {
        notShowStart: true,
        userName: userName,
        photo: userPhoto 
      }
      SentFire(userId, buy)
    }
    //Считаем количество кликов по стартовому экрану
    const countScreen = () => {
      setScreenTap(screenTap+1)
    }
    //Функции добавления и видимости окна шагов
    const stepPlus = () => {
      setStep(50)
      setVisStepFinish(false)
    }
    const stepInfinity = () => {
      setStep(100000000)
      setVisStepFinish(false)
    }
    const getStepVis = () => {
      setVisStepFinish(false)
      
    }

    //Золото за приглашение
    const inviteGold=(item)=> {
        setGold(gold+3000)
        setMaxShare(item)
      }
    const buyGold = () => {
        setGold(prevGold => {
          const newGold = prevGold + 60000;
          // Сохранение игры с новым значением золота
          saveGame(1, newGold);
          return newGold;
        });
      };

    //Покупка отмены рэйдера
    const buyRaider = () =>{
      setCancelRaider(true)
    }

    //Покупка юриста
    const buyLowyer = () =>{
      setAvailLow(true)
      const newLowyer = true
      saveGame(2, newLowyer);
    }

    const [userId, setUserId] = useState(null)
    const [userName, setUserName] = useState(null)
    const [userPhoto, setUserPhoto] = useState(null)

    //Извлекаем id из контакта и ищем в файрбэйз покупки
    const [getBuy, setGetBuy] = useState()
    useEffect(() => {
      const getId = async () => {
        try {
          // Получение информации о пользователе
          const data = await bridge.send('VKWebAppGetUserInfo');
          // ID пользователя
          const userId = data.id.toString();
          const userName = data.first_name.toString()
          const userPhoto = data.photo_100.toString()
          const dataF = await GetFire(userId);
          setUserId(userId)
          setUserName(userName)
          setUserPhoto(userPhoto)
          setGetBuy(dataF);
        //   console.log(dataF);
        } catch (error) {
          console.error('Error getting user info:', error);
        }
      };
  
      getId();
    }, []);


    //Записываем данные о покупках
    useEffect(() => {

      if (getBuy?.notShowStart) {
        setVisStartScreen(false);
      }
      if (getBuy?.steps) {
        setStep(1000000);
      }
      if (getBuy?.raider) {
        setCancelRaider(true);
      }
      if (getBuy?.score) {
        setMaxScore(getBuy.score)
      }
    }, [getBuy]);

    //Записываем результаты (очки в Firebase) с определенной периодичностью
    const [countWrite, setCountWrite] = useState(0)
    //Чтобы сохранялся только максимальный результат
    const [maxScore, setMaxScore] = useState(0)
    
    const writeResult = () => {
      if (countWrite===10) {
      setCountWrite(0)
      if (score>maxScore) {
      console.log("сработало")
      const buy = {
        score: score,
        userName: userName,
        photo: userPhoto 
      }
      SentFire(userId, buy)
    }

    }
    }
    

    //Без звуков
    const [mute, setMute] = useState(true)

    const getMute = () => {
      setMute(!mute)
    }

    //Воспроизведение звука
    const playSound = (soundId) => {
      if (!mute) {
        const audio = document.getElementById(soundId);
        audio.pause();
        audio.currentTime = 0; 
        audio.play(); 
      } 
      };

    //Видимость меню
    const closeModalDem = () => {
      setVisMenu(false)
    }

    const openModalDem = () => {
      setVisMenu(true)
    }

    const getHint = (ind, index) => {
      setVisHint(true)
      setIndHint(ind)
      setIndexHint(index)
    }
    const endHint = () => {
      setVisHint(false)
      setIndHint(null)
      setIndexHint(null)
    }

    //Не сохранять при первом запуске
    // const [needSave, setNeedSave]=useState(false)

    // useEffect(()=>{
    //     if (needSave) {
    //       saveGame()
    //     }
    //   }, gorizNum)

    //Анимация золота и очков не для мешков
    const [visNumberAnime, setVisNumberAnime] = useState(false)
    const [visNumberAnime1, setVisNumberAnime1] = useState(false)
    const [countAnime, setCountAnime] = useState(null)
    const [getInd, setGetInd] = useState(null)
    const [getIndex, setGetIndex] = useState(null)

    const getAnime = (count, getInd, getIndex) => {
      setCountAnime(count)
      setVisNumberAnime1(true)
      setGetInd(getInd)
      setGetIndex(getIndex)
      console.log(count)
      setTimeout(() => {
        setGold(prevGold => prevGold + count); // используем функциональный аргумент
      }, 500);
      setTimeout(()=>{
        setVisNumberAnime1(false)
        setCountAnime(null)
      },2000)
    }
//Анимация для стоящих золотых мешков
    const [countAnimeArray, setCountAnimeArray] = useState([]);
    const [getIndArray, setGetIndArray] = useState([]);
    const [getIndexArray, setGetIndexArray] = useState([]);
  
    const getAnimeGold = (count, getInd, getIndex) => {
      if (getInd===111) {
        setTimeout(()=>{
            setCountAnimeArray([])
            setGetIndArray([])
            setGetIndexArray([])
        },2000)
      } else {
        if(count!==0){
      setVisNumberAnime(true)
      setCountAnimeArray(prevCountAnimeArray => {
        const updatedArray = [...prevCountAnimeArray, count];
        setGold(gold+updatedArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0))
        // console.log(updatedArray);
        return updatedArray;
      });
      
      setGetIndArray(prevGetIndArray => [...prevGetIndArray, getInd]);
      setGetIndexArray(prevGetIndexArray => [...prevGetIndexArray, getIndex]);

      setTimeout(()=>{
        setVisNumberAnime(false)
      },2000)
    }
    }
    };
// Конец
    
//Закрываем информацию
const closeInfo = () => {
  setVisInfo(false)
}
//Открываем информацию
const openInfo = () => {
  setVisInfo(true)
}

//Функция подсчета и добавления очков
    const getScore = (points)=> {
      setScore(score + points)
      setLocalScore(points)
      setVisAnimeScore(true)
      setTimeout(()=>{
        setVisAnimeScore(false)
      }, 2000)
    }

    useEffect(()=>{
      if (countLow === 100 && !availLow) {
        setAvailLow(true)
        setCountLow(0)
      }
    },[countLow, availLow])

    const lowyer = () => {
      setClickLow(true)
    }

    useEffect(() => {
      setTimeout(()=>{loadGame()},500) 
      const timer = setInterval(() => {
        setStep(prevStep => {
          // Проверка на превышение значения 50
          if (prevStep < 50) {
            return prevStep + 1;
          } else {
            return prevStep;
          }
        });
      }, 20000); // 2 секунды
      return () => clearInterval(timer);
      // eslint-disable-next-line
    }, []); 

    //Хранение Массива Гет интро
     const [arrIntro, setArrIntro] = useState([])

     const getArrIntro = (arr) => {
         setArrIntro(arr)
     }

    //Рандомный генератор от 1 до 100
    const generateRandomNumber = () => {
        setChooseNum(null)
        const randomValue = Math.floor(Math.random() * 100) + 1;
        if (randomValue>=3 && randomValue <=75) {
            setChooseNum(1)
            setSaveShop(1)

        } else if (randomValue<3 ) {
          setTimeout(()=>{
            if(numberIntro!==0) {
              setChooseNum(1)
              setSaveShop(1)
            } else {
              if (cancelRaider) {
                setChooseNum(1)
                setSaveShop(1)
              } else {
              setChooseNum(300)
              setSaveShop(300)
              setTimeout(()=>{setNumberIntro(GetIntro(gorizNum, 300, 1, arrIntro, getArrIntro))},50)
              setVisIntro(true)
              }
            }
          },50)
        }
        else if (randomValue<=90 && randomValue>75) {
          //Чтобы не появлялись значения больше чем есть на поле
          const found = gorizNum.some(innerArray => innerArray.includes(2));
          if (found) {
            setChooseNum(2)
            setSaveShop(2)
          } else {
            setChooseNum(1)
            setSaveShop(1)
          }
        } else if (randomValue<=97 && randomValue>90) {
          //Чтобы не появлялись значения больше чем есть на поле
          const found = gorizNum.some(innerArray => innerArray.includes(3));
          if (found) {
            setChooseNum(3)
            setSaveShop(3)
          } else {
            setChooseNum(1)
            setSaveShop(1)
          }

        } else if (randomValue===98){
          setTimeout(()=>{
            if(numberIntro!==0) {
              setChooseNum(1)
              setSaveShop(1)
            } else {
              setChooseNum(1000)
              setSaveShop(1000)
    
              setTimeout(()=>{setNumberIntro(GetIntro(gorizNum, 1000, 1, arrIntro))},50)
              setVisIntro(true)
            }
          },350)

        }else if (randomValue===99) {
          setTimeout(()=>{
            if(numberIntro!==0) {
              setChooseNum(1)
              setSaveShop(1)
            } else {
              setChooseNum(100)
              setSaveShop(100)
    
              setTimeout(()=>{setNumberIntro(GetIntro(gorizNum, 100, 1, arrIntro, getArrIntro))},50)
              setVisIntro(true)
            }
          },50)
        } else if (randomValue>99) {
          //Чтобы не появлялись значения больше чем есть на поле
          const found = gorizNum.some(innerArray => innerArray.includes(4));
          if (found) {
            setChooseNum(4)
            setSaveShop(4)
          } else {
            setChooseNum(1)
            setSaveShop(1)
          }

        }
      };

const [isShop, setIsShop] = useState(false)
const [saveShop, setSaveShop] = useState(1)
//Покупка товара
const buyItem = (number, count) => {
  setChooseNum(number)
  setVisShop(false)
  setIsShop(true)
  setGold(gold-count)
}

//Перенос цифры в тюрьму
const clickJail = () => {
  if (myNumber) {
  // setStyleNumber("headNum2")
  setNumberJail(myNumber)
  generateRandomNumber()
  setMyNumber(null)
  setImgNumber("imgNumber")
  }
}

const getJail = () => {
  // setStyleJail("contJailAnime")
  // setImgNumber("imgNumberAnime")
  setImgNumber("imgNumber")
  setMyNumber(numberJail)
  setIsJail(true)
}

//Подсчет очков
const customSum = (arr) => {
  const sum = arr.reduce((acc, subArr) => {
    const subArrSum = subArr.reduce((subAcc, val) => {
      switch (val) {
        case 1:
          subAcc += 1;
          break;
        case 2:
          subAcc += 6;
          break;
        case 3:
          subAcc += 36;
          break;
        case 4:
          subAcc += 216;
          break;
        case 5:
          subAcc += 1296;
          break;
        case 6:
          subAcc += 11664;
          break;
        case 7:
          subAcc += 69984;
          break;
        case 8:
          subAcc += 419904;
          break;
        case 9:
          subAcc += 2519424;
          break;
        case 10:
          subAcc += 1516544;
          break;
        // Добавьте дополнительные кейсы, если это необходимо
        default:
          // Обработка для других значений, если необходимо
          break;
      }
      return subAcc;
    }, 0);
    return acc + subArrSum;
  }, 0);
  // setScore(sum); 
  return sum
}
//Фоновый звук
const playSoundBack = () => {
    const audio = document.getElementById('backGroundSound');
    audio.volume = 0.3
    audio.play(); 
  } 
  const stopSoundBack = () => {
    const audio = document.getElementById('backGroundSound');
    audio.pause();
    audio.currentTime = 0; // Сбрасывает время воспроизведения на начало
}

//Сохранение игры
      const saveGame = (item, value) => {
          //Для того чтобы золото сохранялось сразу же после покупки
          let newGold = gold
          if (item===1){
              newGold=value
          }
          let newLowyer = availLow
          if (item===2) {
            newLowyer=value
          }
          let newGorizNum = gorizNum
          if (item===3) {
              newGorizNum = value
          }
        const vkKeysOb = {
          'gorizNum':newGorizNum,
          'numberJail':numberJail,
          'gold':newGold,
          'step':step,
          'availLow':newLowyer,
          'countLow':countLow,
          'chooseNum':chooseNum,
          'score':score,
          'needStartScreen':1
        }
      const vkKeys = JSON.stringify(vkKeysOb)

        bridge.send('VKWebAppStorageSet', {
          key: 'vkKeys',
          value: vkKeys })
      
         .then((data) => { 
           if (data.result) {
             // Значение переменной задано
           }
         })
         .catch((error) => {
           // Ошибка
           console.log(error);
         });
      }

      //Загрузка игры
      const loadGame = () => {
        bridge.send('VKWebAppStorageGet', {
          keys: [
            'vkKeys',
          ]})
          .then((data) => { 
            //   console.log(data)
            if (data.keys) {
                const gameState = JSON.parse(data.keys[0].value);
                // console.log(gameState)

                const gorizNumValue = gameState.gorizNum ? gameState.gorizNum  : undefined;
                if (gorizNumValue) {
                     setGorizNum(gorizNumValue)
                    //  console.log(gorizNumValue)
                }

                const numberJailValue = gameState.numberJail ? parseInt(gameState.numberJail) : undefined;
                if (numberJailValue) {
                     setNumberJail(numberJailValue)
                    //  console.log(numberJailValue)
                }

                const goldValue = gameState.gold ? parseInt(gameState.gold) : undefined;
                if (goldValue) {
                     setGold(goldValue)
                    //  console.log(goldValue)
                }

                const stepValue = gameState.step ? parseInt(gameState.step) : undefined;
                if (stepValue) {
                     setStep(stepValue)
                    //  console.log(stepValue)
                }

                const availLowValue = gameState.availLow ;
                     setAvailLow(availLowValue)
                    //  console.log(availLowValue)

                const countLowValue = gameState.countLow ? parseInt(gameState.countLow) : undefined;
                if (countLowValue) {
                     setCountLow(countLowValue)
                    //  console.log(countLowValue)
                }

                const chooseNumValue = gameState.chooseNum ? parseInt(gameState.chooseNum ) : undefined;
                if (chooseNumValue) {
                     setChooseNum(chooseNumValue)
                    //  console.log(chooseNumValue)
                }

                const scoreValue = gameState.score ? parseInt(gameState.score) : undefined;
                if (scoreValue) {
                     setScore(scoreValue)
                    //  console.log(scoreValue)
                }

                const needStartScreenValue = gameState.needStartScreen ? parseInt(gameState.needStartScreen) : undefined;
                if (needStartScreenValue===1) {
                    setVisStartScreen(false)
                    // console.log(needStartScreenValue)
                }

                // console.log(data.keys)
            }
    
          })
          .catch((error) => {
            // Ошибка
            console.log(error);
          });

      }
      
  //Клик по кнопке с номером
  const clickNumber = () => {
    setClickLow(false)
    setMyNumber(chooseNum)
    setIsJail(false)
    setImgNumber("imgNumberAnime")
  }

  //Прослушивание события для воспроизведения фона
  const [hideApp, setHideApp] = useState(false)

  useEffect(()=>{
    if (hideApp) {
      stopSoundBack()
      setMute(true)
    }
  },[hideApp])

  useEffect(()=>{
    const visChange = () => {
      setHideApp(document.hidden)

    }
    document.addEventListener("visibilitychange", visChange);
  })


//При первом запуске для гет интро извлекаем данные из ВК, при последующих нет\

const [firstGetIntro, setFirstGetIntro] = useState(false)
  useEffect(()=>{
    if (!firstGetIntro) {
    bridge.send('VKWebAppStorageGet', {
        keys: [
          'arrIntro'
        ]})
        .then((data) => { 
          if (data.keys) {
              const gameStateField = JSON.parse(data.keys[0].value);
              setNumberIntro(GetIntro(gorizNum, chooseNum, 2, gameStateField, getArrIntro))
              setArrIntro(gameStateField)
              setTimeout(()=>{setFirstGetIntro(true)},300)
          }
        })
        .catch((error) => {
            setNumberIntro(GetIntro(gorizNum, chooseNum, 2, arrIntro, getArrIntro))
            setTimeout(()=>{setFirstGetIntro(true)},300)
          // Ошибка
          console.log("Ошибка"+ error);

        });
    }
    // eslint-disable-next-line
  },[])

  //Данные для поделиться, чтобы нельзя было делиться одним результатом 2 раза или меньшим результатом
  const [maxShare, setMaxShare] = useState(0)

  useEffect(()=>{
    bridge.send('VKWebAppStorageGet', {
        keys: [
          'maxShare'
        ]})
        .then((data) => { 
          if (data.keys) {
              const itemJ = JSON.parse(data.keys[0].value);
              const item = parseInt(itemJ)
              setMaxShare(item)
          }
        })
        .catch((error) => {
          // Ошибка
          console.log("Ошибка"+ error);

        });
    // eslint-disable-next-line
  },[])

  //Разрешение на проигрывания звуков
  const [allPlay, setAllPlay] = useState(false)
  // const [noPlay, setNoPlay] = useState(true)
  //ВЫЗОВ РАСЧЕТОВ!!!!!!!!!!!!!!!!!!!!!!!
    useEffect(() => {
      // eslint-disable-next-line
        const [result1, result2] = Match(gorizNum, chooseIndA, chooseIndB, getAnimeGold, getAnime, getScore);
        // setGold(gold+result2)
        if(allPlay && result2===0) {
          if (saveNum===1000) {
            playSound("moneySound")
          } else {
            // console.log(saveNum)
             playSound("get")
          }
        } else if (allPlay && result2>0 ) {
          saveGame(3, result1)
          playSound("collect")
          //Записываем score в таблицу (раз в 10 collect)
          writeResult()
          if (countWrite<10) {
          setCountWrite(countWrite+1)
          }
        } 
        setGorizNum(result1)
        //При первом запуске не запускаем
        if (firstGetIntro) {
            setNumberIntro(GetIntro(result1, chooseNum, 2, arrIntro, getArrIntro))
            
        }
        customSum(result1)
        if (result1.some(subArray => subArray.includes(0))) {
        } else {
            setVisFinish(true)
        }
// eslint-disable-next-line
    },[check])

//Если есть значение в подсказке открываем окно
    useEffect(() => {
      if (numberIntro !== 0) {
          setVisIntro(true)

      }
  }, [numberIntro]);

    //Ход
    const setting = (index, ind) => {
      setAllPlay(true)
      //Нужно добавить если сохранение каждый ход
    //   setNeedSave(true)
      if (step>0) {
        //Рейдер
        if (myNumber===300 && 
          gorizNum[index][ind]!==0 && 
          gorizNum[index][ind]!==300 &&
          gorizNum[index][ind]!==301 &&
          gorizNum[index][ind]!==302 &&
          gorizNum[index][ind]!==303 &&
          gorizNum[index][ind]!==304 &&
          gorizNum[index][ind]!==305 &&
          gorizNum[index][ind]!==306 &&
          gorizNum[index][ind]!==307 &&
          gorizNum[index][ind]!==308 &&
          gorizNum[index][ind]!==309 &&
          gorizNum[index][ind]!==310 &&
          gorizNum[index][ind]!==311 &&
          gorizNum[index][ind]!==312 &&
          gorizNum[index][ind]!==313 &&
          gorizNum[index][ind]!==314 &&
          gorizNum[index][ind]!==315 &&
          gorizNum[index][ind]!==1301 &&
          gorizNum[index][ind]!==1302 &&
          gorizNum[index][ind]!==1303 &&
          gorizNum[index][ind]!==1304
          ){
        playSound("lockBlock")
        // setNoPlay(false)
        // setTimeout(()=>{setNoPlay(true)},1000)
        //шаги до юриста
        if (!availLow) {
          setCountLow(countLow+1)
          }
          setStep(step-1)
          setImgNumber("imgNumber")
          setBackNum(gorizNum.map(innerArray => [...innerArray]))
          setSaveNum(chooseNum)
          setSaveNumberJail(numberJail)
          setBackVis(true)
          const newGorizNum = [...gorizNum];
          newGorizNum[index][ind] = gorizNum[index][ind]+300;
          // setCheck(!check)
          setMyNumber(null)
          if (isJail) {
            setNumberJail(null)
            setIsJail(false)
          } else {
            generateRandomNumber()
          }
        }
        if (clickLow && gorizNum[index][ind]!==0 &&
          gorizNum[index][ind]!==0 && 
          
          gorizNum[index][ind]!==300 &&
          gorizNum[index][ind]!==301 &&
          gorizNum[index][ind]!==302 &&
          gorizNum[index][ind]!==303 &&
          gorizNum[index][ind]!==304 &&
          gorizNum[index][ind]!==305 &&
          gorizNum[index][ind]!==306 &&
          gorizNum[index][ind]!==307 &&
          gorizNum[index][ind]!==308 &&
          gorizNum[index][ind]!==309 &&
          gorizNum[index][ind]!==310 &&
          gorizNum[index][ind]!==311 &&
          gorizNum[index][ind]!==312 &&
          gorizNum[index][ind]!==313 &&
          gorizNum[index][ind]!==314 &&
          gorizNum[index][ind]!==315 &&
          gorizNum[index][ind]!==1301 &&
          gorizNum[index][ind]!==1302 &&
          gorizNum[index][ind]!==1303 &&
          gorizNum[index][ind]!==1304
          ) {
          setBackVis(true)
          //Для юриста
          setAvailLow(false)
          setChooseNum(gorizNum[index][ind])
          setClickLow(false)
          playSound("hide")
          setLowNumber(chooseNum)
          setGorizNum(prevArray => {
            const newArray = [...prevArray]; // Создаем копию предыдущего массива
            newArray[index][ind] = 0; // Заменяем элемент с указанным индексом на новое значение
            return newArray; // Возвращаем новый массив
          });
        } else {
          setClickLow(false)
          customSum(gorizNum)
      if (myNumber && myNumber!==100 && gorizNum[index][ind]===0 && myNumber!==300) {
        //шаги до юриста
        if (!availLow) {
        setCountLow(countLow+1)
        }
        setStep(step-1)
        setImgNumber("imgNumber")
        setBackNum(gorizNum.map(innerArray => [...innerArray]))
        setSaveNum(chooseNum)
        setSaveNumberJail(numberJail)
        setBackVis(true)
        const newGorizNum = [...gorizNum];
        newGorizNum[index][ind] = myNumber;
        setGorizNum(newGorizNum)
        setCheck(!check)
        setMyNumber(null)
        if (isJail) {
          setNumberJail(null)
          setIsJail(false)
        } else {
          if (isShop){
            setChooseNum(saveShop)
            setIsShop(false)
          } else {
          if(lowNumber) {
            setChooseNum(lowNumber)
            setLowNumber(null)
          } else {
          generateRandomNumber()
          }
        }
        }
    } else {
      if (!availLow) {
        setCountLow(countLow+1)
        }
      setStep(step-1)
      if (myNumber===100 && gorizNum[index][ind]!==0) {
        playSound("agentSell")
        agentGold(gorizNum[index][ind], index, ind)
        setImgNumber("imgNumber")
        // setStyleJail("contJail")
        setBackNum(gorizNum.map(innerArray => [...innerArray]))
        setSaveNum(chooseNum)
        setSaveNumberJail(numberJail)
        setBackVis(true)
        const newGorizNum = [...gorizNum];
        newGorizNum[index][ind] = 0;
        setGorizNum(newGorizNum)
        setMyNumber(null)
        if (isJail) {
          setNumberJail(null)
          setIsJail(false)
        } else {
          if (isShop){
            setChooseNum(saveShop)
            setIsShop(false)
          } else {
          if(lowNumber) {
            setChooseNum(lowNumber)
            setLowNumber(null)
          } else {
          generateRandomNumber()
          }
        }}
    }}
  }
} else {
  setVisStepFinish(true)
}
}

  //Расчет золота за использования налоговой
  const agentGold = (number, ind, index) => {
    if (number===1) {
      setGold(gold+0)
    } else if (number===2) {
      setGold(gold+2)
      getAnime(2, index, ind)
    } else if (number===3) {
      setGold(gold+5)
      getAnime(5, index, ind)
    } else if (number===4) {
      setGold(gold+7)
      getAnime(7, index, ind)
    } else if (number===5) {
      setGold(gold+15)
      getAnime(15, index, ind)
    } else if (number===6) {
      setGold(gold+25)
      getAnime(25, index, ind)
    } else if (number===7) {
      setGold(gold+50)
      getAnime(50, index, ind)
    } else if (number===8) {
      setGold(gold+750)
      getAnime(750, index, ind)
    } else if (number===9) {
      setGold(gold+2500)
      getAnime(2500, index, ind)
    } else if (number===10) {
      setGold(gold+6000)
      getAnime(6000, index, ind)
    } else if (number===11) {
      setGold(gold+15000)
      getAnime(15000, index, ind)
    } else if (number===1001) {
      setGold(gold+100)
      getAnime(100, index, ind)
    } else if (number===1002) {
      setGold(gold+250)
      getAnime(250, index, ind)
    } else if (number===1003) {
      setGold(gold+10000)
      getAnime(10000, index, ind)
    } else if (number===1004) {
      setGold(gold+15000)
      getAnime(15000, index, ind)
    }

  }

    const stepBack = () => {
        setGorizNum(backNum)
        setChooseNum(saveNum)
        setNumberJail(saveNumberJail)
        setBackVis(false)
        setGold(gold-1500)
      }
    
    const closeShop = () => {
      setVisShop(false)
    }

//Эксперементирую с перетаскиванием
const handleTouchEnd = (event, adjustedX, adjustedY) => {
  let endBlock 
  let one
  let endBlockJail 
  endBlockJail = document.elementFromPoint(adjustedX, adjustedY+50)
    if (adjustedX-100>0) {
      endBlock = document.elementFromPoint(adjustedX-85, adjustedY);
      one=1
    } else {
      endBlock = document.elementFromPoint(adjustedX+60, adjustedY)

      one=2
    }

  if (endBlock && endBlock.dataset.rowIndex && endBlock.dataset.colIndex && parseInt(endBlockJail.dataset.rowIndex)!==100 && parseInt(endBlock.dataset.rowIndex)!==100) {
    if (one===1) {
    const index = parseInt(endBlock.dataset.rowIndex);
    const ind = parseInt(endBlock.dataset.colIndex)+1;
    setting(index, ind);
    setChooseIndA(index);
    setChooseIndB(ind);
    setOpx(12);
    setOpy(12);
    }
    else if (one===2) {
      const index = parseInt(endBlock.dataset.rowIndex);
      const ind = parseInt(endBlock.dataset.colIndex)-1;
      setting(index, ind);
      setChooseIndA(index);
      setChooseIndB(ind);
      setOpx(12);
      setOpy(12);
    }
  } else if (endBlockJail && parseInt(endBlockJail.dataset.rowIndex)===100) {
    setClickLow(false); 
    if (!numberJail) {
      clickJail()
    }else {
      getJail()
    } 
  }
};


const [opx, setOpx] = useState()
const [opy, setOpy] = useState()

const handleTouchMove = (event, adjustedX, adjustedY) => {
  let targetBlock 
  let one
  if (adjustedX-100>0) {
    targetBlock = document.elementFromPoint(adjustedX-85, adjustedY);
    one=1
  } else {
    targetBlock = document.elementFromPoint(adjustedX+60, adjustedY)
    one=2
  }

  if (targetBlock && targetBlock.dataset.rowIndex && targetBlock.dataset.colIndex) {
    if (one===1) {
    const index=parseInt(targetBlock.dataset.rowIndex)
    const ind = parseInt(targetBlock.dataset.colIndex)+1
    setOpx(index)
    setOpy(ind)
    } else if (one===2) {
      const index=parseInt(targetBlock.dataset.rowIndex)
      const ind = parseInt(targetBlock.dataset.colIndex)-1
      setOpx(index)
      setOpy(ind)
    }


};
}

    // Кнопка для очистки интро
    // const cleanIntroVk = () => {
    //     console.log("Нажали очистку истории")
    //     bridge.send('VKWebAppStorageSet', {
    //         key: "arrIntro",
    // })
    // }

    return (
      <div className="contCont" >
        <PreloadImages/>
        {visStart && 
        <Start closeStart={closeStart} 
        mute={mute} 
        getMute={getMute} 
        playSoundBack={playSoundBack}
        stopSoundBack={stopSoundBack}/>}
        <div className="fieldCont" >
          <div  style={{backgroundImage: `url(${topWall})`, backgroundSize: 'cover', position:"fixed", top:"0", width:"100%", maxWidth:"450px" ,height:"30%", zIndex:"-1", backgroundColor:"rgb(80, 121, 69)"}} ></div>
          <div className="fieldUnder" style={{backgroundImage: `url(${wallMoney})`, backgroundSize: 'cover'}}></div>
         <div className="contScorePoint" >
           {visFinish && 
             <FinishScreen
              gorizNum={gorizNum}
              />}
           {numberBuy>0 && 
              <VkBuy 
                numberBuy={numberBuy} 
                getNumberBuy={getNumberBuy}
                stepInfinity={stepInfinity}
                buyGold={buyGold}
                buyRaider={buyRaider}
                buyLowyer={buyLowyer}
                />}
           {visStepFinish && 
              <StepsOut getStepVis={getStepVis} stepPlus={stepPlus} stepInfinity={stepInfinity} getNumberBuy={getNumberBuy}/>}
           {visStartScreen && !visStart &&
              <StartScreen closeStartScreen={closeStartScreen} countScreen={countScreen}/>}
           {visInfo && 
              <Information closeInfo={closeInfo} gorizNum={gorizNum}/>}
           {visMenu && 
               <MenuDem
                  closeModalDem={closeModalDem}
                  getMute={getMute}
                  mute={mute}
                  gorizNum={gorizNum}
                  score={score}
                  playSoundBack={playSoundBack}
                  stopSoundBack={stopSoundBack}
                  maxShare={maxShare}
                  inviteGold={inviteGold}
                  />
              
            }
          
           {visAnimeScore && 
              <ScoreAnime localScore={localScore}/>}
              <Score 
                    customSum={customSum}
                    gorizNum={gorizNum}
                    gold={gold}
                    step={step}
                    score={score}
                    screenTap={screenTap}
          />
              <Points 
                availLow={availLow} 
                lowyer={lowyer} 
                handleTouchEnd2={handleTouchEnd}
                countLow={countLow}
                stepBack={stepBack}
                backVis={backVis}
                gold={gold}
                openInfo={openInfo}
                openModalDem={openModalDem}
                getMute={getMute}
                mute={mute}
                screenTap={screenTap}
                />
            </div>
          {visShop && 
              <ModalShop closeShop={closeShop} buyItem={buyItem} gold={gold} getNumberBuy={getNumberBuy} step={step} cancelRaider={cancelRaider} availLow={availLow}/>}
          <div className="test" style={{backgroundImage: `url(${grass1})`}}>
            {/* <img src={frame} alt="f" style={{position:"absolute", width:"110%", height:"110%", top:"-5%", left:"-5%"}}/> */}
          <div className="grid-container2" >
            {gorizNum.map ((numb, index) => (
                numb.map((number, ind)=>(
                <div 

              data-row-index={index}
              data-col-index={ind}
                style={{
                  opacity: index===opx && ind === opy && number===0 && "0",
                  // border: index===opx && ind === opy && number===0 && "2px solid black",
                  // boxSizing: "border-box",
                  // backgroundColor:number===0 ? "rgba(255, 255,255, 0.2)":null,
                  background:number!==0 && "rgba(255, 215, 0, 0)",
                  userSelect:"none",
                  // backgroundImage: number===0 ? `url(${cell})` : null,
                  // backgroundSize: number===0 ? "100% 100%" : null,
                  // border: number===0 ? "4px solid rgb(19%, 47%, 11%, 0.9)":null,
                  // borderImage:`url(${cell}) round 100`,
                  boxShadow: number===0 ? "0px 0px 2px rgba(0, 0, 0, 0.4)": null,
                  // boxShadow:"0px 0px 4px rgba(0, 0, 0, 0.4)",
                  // animationName: number===0 ? "grass2": null,
                  animationDelay: `${ind+index}s`
                }} 
                    key={ind} 
                    className="grid-item2"
                    onTouchStart={()=>getHint(ind, index)}
                    onTouchEnd={endHint}
                    >
                    {visHint && indHint===ind && indexHint===index && number!==0 && 
                    <ModalHint number={number} index={ind}/>}
                    {number!==0 && 
                    <img 
                        data-row-index={index}
                        data-col-index={ind}
                        style={{width:"55px", height:"55px", pointerEvents:"none", userSelect:"none"}} 
                        src={number===1 || number===301? p1: number===2|| number===302 ? p2 : number===3|| number===303 ? p3 : number===4 || number===304? p4 : number===5 || number===305? p5 : number===6 || number===306? p6 : number===7 || number===307? p7 : number===8 || number===308? p8 : number===9 || number===309? p9 : number===10 || number===310 ? p10 : number===11 || number===311 ? p11 : number===12 || number===312 ? p12 : number===13 || number===313 ? p13 : number===1001 || number===1301? p1001: number===1002 || number===1302? p1002: number===1003 || number===1303? p1003: number===1004 || number===1304? p1004: number===11 || number===311 ? p11: quiz  } alt={numb} 
                    />
                    }
                    {number!==0 && 
                      (number===301||number===302||number===303||number===304||number===305||number===306||number===307||number===308||number===309||number===310 ||number===311||number===312||number===313||number===1301||number===1302||number===1303||number===1304)&&
                    <img 
                        data-row-index={index}
                        data-col-index={ind}
                        style={{position:"absolute", width:"35px", height:"35px", pointerEvents:"none", userSelect:"none", right:"0px", bottom:"0px", opacity:"1"}} 
                        src={lock} alt={numb} 
                    />
                    }
                    {visNumberAnime1 && countAnime && (getInd===ind && getIndex===index) &&  
                      <NumberAnime  countAnime={countAnime}/>
                    }

                    {getIndexArray&& visNumberAnime && countAnimeArray && countAnimeArray.map((countAnime, i) => (
                            index===getIndexArray[i] && ind===getIndArray[i] &&
                            <NumberAnime key={i} countAnime={countAnime} />
                          ))}

                </div>
            ))))} 
            </div>
          </div>
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"100%", maxWidth:"450px", minWidth:"350px", paddingLeft:"10px", paddingRight:"10px", boxSizing:"border-box"}} >
                     <div  style={{marginTop:"1px"}}
                            data-row-index={100}
                            data-col-index={100}
                     >
                      <Jail 
                      getJail={getJail}
                      numberJail={numberJail}
                      handleTouchEnd2={handleTouchEnd}
                      handleTouchMove2={handleTouchMove}
                      screenTap={screenTap}
                       />
                    </div>
                    

                    <TestDrag 
                    chooseNum={chooseNum} 
                    gorizNum={gorizNum} 
                    clickNumber={clickNumber} 
                    handleTouchEnd2={handleTouchEnd} 
                    handleTouchMove2={handleTouchMove} 
                    imgNumber={imgNumber}
                    playSound={playSound}
                    screenTap={screenTap}
                    />
                    <div onClick={() => {screenTap !== 3 && setVisShop(true)}} className="contShop" 
                      style={{
                        zIndex:screenTap===3 ? "100000":"",
                        position:screenTap===3 ? "relative":"",
                      }}>
                      <p className="shopP">БИРЖА</p>

                    </div>
              </div>
              {visIntro && <Introduce closeIntro={closeIntro} number={numberIntro} inviteGold={inviteGold}/>}
              <div  className="contBottom"></div>

              {/* <audio id="choose"  src={choose}></audio> */}
              {get && <audio id="get"  src={get}></audio>}
              {collect && <audio id="collect"  src={collect}></audio>}
              {lockBlock && <audio id="lockBlock"  src={lockBlock}></audio>}
              {agentSell && <audio id="agentSell"  src={agentSell}></audio>}
              {moneySound && <audio id="moneySound"  src={moneySound}></audio>}
              {hide && <audio id="hide"  src={hide}></audio>}
              {backGroundSound &&  <audio id="backGroundSound" src={backGroundSound} loop></audio>}
              {/* <button onClick={cleanIntroVk}>VK</button> */}

        </div>
        </div>
    )
}

export {Field}