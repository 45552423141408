import './Score.css'
import p0 from'./pogony/p0.png'
import medal from './image/medal.png'
import step from './image/step.png'

const Score = props => {
    return (
        <div className="contScore" >
            <div className="eachScore">
                <div className="contImg">
                    <img className="imgScore" src={medal} alt="1" style={{width:"30px", height:"30px"}}/>
                </div>
                <div className="contP">
                    <p className="pScore">{props.score}</p>
                </div>
            </div>
            <div className="eachScore" style={{
                position: props.screenTap===4 ? "relative":"",
                zIndex: props.screenTap===4 ? "100000":""
            }}>
                <div className="contImg" >
                    <img className="imgScore" src={p0} alt="2"/>
                </div>
                <div className="contP">
                    <p className="pScore">{props.gold}</p>
                </div>
            </div>
            <div className="eachScore" >
                <div className="contImg">
                    <img className="imgScore" src={step} alt="3"/>
                </div>
                <div className="contP">
                    <p className="pScore" style={{fontSize: props.step>2000 ? "28px": "", fontWeight: props.step>2000 ? "normal" :"bold", marginBottom: props.step>2000 ? "3px": ""}}>{props.step>2000 ? '\u221E': props.step }</p>
                </div>
            </div>
        </div>
    )
}

export default Score