
const BigNumber = (data) => {

    const flattenedArray = data.flat();
    var textItem = ''

    // Отфильтруем значения меньше 300
    const filteredArray = flattenedArray.filter(num => num < 300);
  
    // Найдем максимальное значение среди отфильтрованных
    const maxNumber = Math.max(...filteredArray);
    if (maxNumber===1) {
        textItem="Бронзовая монета"
    }
    if (maxNumber===2) {
        textItem="Серебрянная монета"
    }
    if (maxNumber===3) {
        textItem="Золотая монета"
    }
    if (maxNumber===4) {
        textItem="Стартовый капитал"
    }
    if (maxNumber===5) {
        textItem="Кафе"
    }
    if (maxNumber===6) {
        textItem="Магазин"
    }
    if (maxNumber===7) {
        textItem="Отель"
    }
    if (maxNumber===8) {
        textItem="Завод"
    }
    if (maxNumber===9) {
        textItem="Электростанция"
    }
    if (maxNumber===10) {
        textItem="Банк"
    }
    if (maxNumber===11) {
        textItem="Железная дорога"
    }
    if (maxNumber===12) {
        textItem="Авиакомпания"
    }
    if (maxNumber===13) {
        textItem="Нефтедобывающая компания"
    }

    return textItem
}

export default BigNumber